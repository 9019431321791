import { ALL_CREDIT_APPLICATIONS } from "../actions/types";
const initialState = {
  list: []
};
export default function(state = initialState, action) {
  switch (action.type) {
    case ALL_CREDIT_APPLICATIONS:
      return { ...state, list: action.payload };
    default:
      return state;
  }
}
