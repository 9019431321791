import { AUTH_USER, AUTH_USER_LOGOUT } from "../actions/types";

const INITIAL_STATE = {
  authenticated: "",
  role:"",
  registered_domain: "",
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_USER:
      if(action.payload !== undefined) {
        return { ...state, authenticated: action.payload.token, role: action.payload.role, registered_domain:action.payload.registered_domain };
      }
      return { ...state, authenticated: "", role: "", registered_domain: "" };
    case AUTH_USER_LOGOUT:
      return { ...state, authenticated: action.payload };
    default:
      return state;
  }
}
