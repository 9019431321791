import React, { Component } from "react";

export default class editemailtemplate extends Component {
  render() {
    return [
      <div className="content-head">
        <div className="content-head-left">
          <h1 className="content-head__title">Email Template</h1>
        </div>
      </div>,
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <div>
              <label>
                <span>Template Name</span>
              </label>
              <input className="form-control" type="text"></input>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="form-group">
            <div>
              <label>
                <span>Subject Text</span>
              </label>
              <input className="form-control" type="text"></input>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6">
          <div className="form-group">
            <div>
              <label>
                <span>Recipient</span>
              </label>
              <input className="form-control" type="text"></input>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6">
          <div className="form-group">
            <strong className="d-block">Status</strong>
            <div className="form-check form-check-inline pt-3">
              <div className="d-flex align-items-center">
                <input
                  type="radio"
                  id="status-active"
                  className="form-check-input"
                  name="status"
                ></input>

                <label className="form-check-label pl-0" for="status-active">
                  Active
                </label>
              </div>

              <div className="d-flex align-items-center ml-4">
                <input
                  type="radio"
                  id="status-inactive"
                  className="form-check-input"
                  name="status"
                ></input>

                <label className="form-check-label pl-0" for="status-inactive">
                  Inactive
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <label>
            <span>Email content</span>
          </label>
          <textarea />
        </div>

        <div className="col-12 mt-3">
          <button className="btn btn-primary">Update</button>
        </div>
      </div>
    ];
  }
}
