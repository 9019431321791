import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import Translate from "react-translate-component";
import { ToEuro } from "../../../utils/misc";
import {
  creditReqDetail,
  changeApplicationStatus,
  comment
} from "../../../actions/creditApplications";
import{
  creditReqDetailEditor,
  changeApplicationStatusEditor,
  commentEditor,
  changeApplicationMatchingStatusEditor
} from "../../../actions/Editor/creditApplications";

import { downloadFile } from "../../../actions/downloadFile";
import { clearError } from "../../../actions/clearError";
import { textAreaField } from "../../formFields";
import Moment from "react-moment";
import Loading from "../../general/Loading";
import { Link } from "react-router-dom";
import FileList from "../../general/FileList";
class View extends Component {
  state = { detail: null, show: true };
  componentDidMount() {
    this.props.clearError();
    const { id } = this.props.location.state;
    if (this.props.role === "admin"){
      this.props.creditReqDetail(id);
    } else {
      this.props.creditReqDetailEditor(id);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.detail !== this.props.detail) {
      this.setState({ detail: this.props.detail.detail });
    }
  }
  showCollections = collection => {
    if (collection.length === 0) {
      return <div>Not Available</div>;
    } else {
      return collection.map((object, index) => {
        return (
          <span key={index}>
            {object.name[this.props.language]} <br />
          </span>
        );
      });
    }
  };
  showNameValuePair = (arrayOfObjects, multilingual=false) => {
    if (arrayOfObjects.length === 0) {
      return (
        <Translate
          content="message.not_available"
          component="p"
          className="product__info"
        />
      );
    } else {
      return arrayOfObjects.map((object) => {
        return (
          <div className="col-3 col-md-6 col-sm-12 col-lg-3" key={object.id}>
            <div className="d-flex mb-2">
              <span className="mr-3"> {multilingual?object.name[this.props.language]:object.name} </span>
              <span>{object.value}</span>
            </div>
          </div>
        );
      });
    }
  };
  showServices = services => {
    return services.map((service, index) => {
      return (
        <Link className="mb-1" to="#">
          {service.name[this.props.language]}
        </Link>
      );
    });
  };
  operation = () => {
    this.setState({
      show: !this.state.show
    });
  };
  renderDocs = docs => {
    if (docs.length === 0) {
      return (
        <span>
          <Translate content="column.noattachment" />
        </span>
      );
    }
    return (
      <FileList documents={docs} />
    )
  };
  onSubmit = formProps => {
    if (this.props.role === "admin") {
      this.props.comment(this.props.location.state.id, formProps);
    } else {
      this.props.commentEditor(this.props.location.state.id, formProps);
    }
    
  };
  changeApplicationStatus = (id) => {
    if (this.props.role === "admin") {
      this.props.changeApplicationStatus(id, "rejected", () => {
        this.props.history.push("/creditor/credit-requests");
      });
    } else {
      this.props.changeApplicationStatusEditor(id, "rejected", () => {
        this.props.history.push("/creditor/credit-requests");
      })
    }
  }
  changeApplicationMatchingStatus= (id, status) => {
    return this.props.changeApplicationMatchingStatusEditor(id, status, () => {
      this.props.history.push("/creditor/credit-requests");
    });
  }

  render() {
    if (this.state.detail) {
      const {
        amount,
        created_on,
        creditor,
        deadline,
        description,
        id,
        service,
        status,
        time_duration,
        state,
        county,
        industries,
        sales,
        collaterals,
        payment_after,
        nda_requirement,
        ratings,
        registered_domain,
        matching_options,
        files
      } = this.state.detail;
      const { handleSubmit } = this.props;
      return (
        <Fragment>
          <div className="content-head">
            <div className="content-head-left">
              <h1 className="content-head__title">
                <Translate content="sidebar.credit_requests" />{" "}
              </h1>
            </div>
          </div>
          <div className="content-body credit-request">
            <div className="row">
              <div className="col-12 col-lg-12 col-xl-8">
                <div className="row">
                  <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                    {/* <h6>States</h6> */}
                    <h6>
                      <Translate content="label.state" />
                    </h6>
                    <span>{state ? state.name : null}</span>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                    <h6>
                      <Translate content="label.country" />
                    </h6>
                    <span>{county ? county.name : null}</span>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6 col-lg-3 res-m-t-1">
                    <h6>
                      <Translate content="column.credittype" />
                    </h6>
                    <span>
                      {service.name
                        ? service.name[this.props.language]
                        : null}
                    </span>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6 col-lg-3 res-m-t-1">
                    <h6>
                      <Translate content="label.industries" />
                    </h6>
                    <div className="d-flex flex-wrap justify-content-between flex-column">
                      {industries ? this.showCollections(industries) : null}
                    </div>
                  </div>
                </div>
                <div className="row justify-content-between w-100 mt-5">
                  <div className="col-12">
                    <h6>
                      <Translate content="label.purpose" />
                    </h6>
                    <span>{description}</span>
                  </div>
                </div>
                <div className="row justify-content-between w-100 mt-5 credit-request-content">
                  <div className="col-6 col-md-6 col-sm-12 col-lg-3">
                    {/* <h6>States</h6> */}
                    <h6>
                      <Translate content="label.salesAmount" />
                    </h6>
                    <span>
                      <ToEuro amount={sales} />
                    </span>
                  </div>
                  <div className="col-6 col-md-6 col-sm-12 col-lg-3">
                    <h6>
                      <Translate content="label.Sicherheiten" />
                    </h6>
                    <span>
                      {collaterals ? this.showNameValuePair(collaterals) : null}
                    </span>
                  </div>
                </div>
                <div className="row justify-content-between w-100 mt-5 credit-request-content">
                  <div className="col-6 col-md-6 col-sm-12 col-lg-3">
                    <h6>
                      <Translate content="label.deadline" />
                    </h6>
                    <span>
                      <Moment format="DD.MM.YYYY">{deadline}</Moment>
                    </span>
                  </div>
                  <div className="col-6 col-md-6 col-sm-12 col-lg-3">
                    <h6>
                      <Translate content="label.deadlineForPayment" />
                    </h6>
                    <span>
                      <Moment format="DD.MM.YYYY">{payment_after}</Moment>
                    </span>
                  </div>
                  {!nda_requirement ? (
                    <div className="col-6 col-md-6 col-sm-12 col-lg-3">
                      <h6>
                        <Translate content="label.nda" />
                      </h6>
                      <span>
                        <Translate content="label.yes" />
                      </span>
                    </div>
                  ) : (
                    <Fragment></Fragment>
                  )}
                </div>
                <div className="row justify-content-between w-100 mt-5 credit-request-content">
                  <h6 className="w-100">
                    <Translate content="label.ratingForCredit" />
                  </h6>
                  {ratings ? this.showNameValuePair(ratings,true) : null}
                </div>
              </div>
              <div className="col-lg-12 col-xl-4 rightbar">
                <div className="amount">
                  <h6>
                    <Translate content="label.investment_available_of" />
                  </h6>
                  <h2>
                    <ToEuro amount={amount} />
                  </h2>
                  <p className="font-italic">
                    <Translate content="message.tax" />
                  </p>
                </div>
                <div className="investor clearfix mt-5">
                  <h6>
                    <Translate content="sidebar.creditor" />
                  </h6>
                  <div className="investor-profile">
                    <Link
                      className=""
                      to={{
                        pathname: "/user/detail",
                        state: { id: creditor.id, from: "creditor" }
                      }}
                    >
                      {creditor.full_name}
                    </Link>
                  </div>
                </div>
                <div className="date date-created mt-5">
                  <h6>
                    <Translate content="column.createdon" />
                  </h6>
                  <Moment format="DD.MM.YYYY">{created_on}</Moment>
                </div>
                <div className="date mt-5">
                  {/* <h6>Time Duration</h6> */}
                  <h6>
                    <Translate content="label.time" />
                  </h6>
                  <span>{time_duration} Monate</span>
                </div>
                <div className="date date-created mt-5">
                  <h6>Status</h6>
                  {status}
                </div>
                <div className="mt-5">
                  <h6><Translate content="label.matching_option" /></h6>
                  {matching_options}
                </div>
                <div className="mt-5">
                  <h6><Translate content="column.domain" /></h6>
                  {registered_domain}
                </div>
              </div>
            </div>
            <div className="row justify-content-between w-100 mt-4 pl-3">
              <div className="attachments mt-5 mb-5">
                <h4>
                  <Translate content="label.attachments" />
                </h4>
                {this.renderDocs(files)}
              </div>
            </div>
            <br />
            {this.props.errorMsg ? (
              <font color="red">{this.props.errorMsg}</font>
            ) : null}

            { registered_domain === this.props.registered_domain || ( this.props.role === 'admin' && matching_options === 'delegate') ? 
            (
              <div className="d-flex">
                <div className="item">
                  <button
                    className="btn btn-info mr-3"
                    onClick={() => this.operation()}
                  >
                    <Translate content="button.moreDetail" />
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() =>
                      this.props.history.push({
                        pathname: "/creditor/matched-products",
                        state: { id }
                      })
                    }
                  >
                    <Translate content="button.show_match" />
                  </button>
                  {status === "requested" ||
                  status === "open" ||
                  status === "questions_for_creditor" ? (
                    <button
                      className="btn btn-danger ml-3"
                      onClick={() =>
                        this.changeApplicationStatus(id)
                      }
                    >
                      <Translate content="button.reject" />
                    </button>
                  ) : null}

                  { this.props.role !== "admin" && (matching_options === "auto" ||
                  matching_options === "manual") ? (
                    <button
                      className="btn btn-info ml-3"
                      onClick={() =>
                        this.changeApplicationMatchingStatus(id,'delegate')
                      }
                    >
                      <Translate content="button.matching_deligate" />
                    </button>
                  ) : null}
                  {  this.props.role !== "admin" && (matching_options === "auto" &&
                  matching_options !== "delegate") ? (
                    <button
                      className="btn btn-warning ml-3"
                      onClick={() =>
                        this.changeApplicationMatchingStatus(id,'manual')
                      }
                    >
                      <Translate content="button.matching_manual" />
                    </button>
                  ) : null}
                  {  this.props.role !== "admin" && (matching_options === "manual" &&
                  matching_options !== "delegate") ? (
                    <button
                      className="btn btn-warning ml-3"
                      onClick={() =>
                        this.changeApplicationMatchingStatus(id,'auto')
                      }
                    >
                      <Translate content="button.matching_auto" />
                    </button>
                  ) : null}
                  
                  {this.state.show ? null : (
                    <Fragment>
                      <div className="content-head mt-5">
                        <div className="content-head-left">
                          <h4 className="content-head__title">
                            <Translate content="button.moreDetail" />
                          </h4>
                        </div>
                      </div>
                      <div className="content-body mt-3">
                        <form onSubmit={handleSubmit(this.onSubmit)}>
                          <Field
                            type="text"
                            component={textAreaField}
                            name="comment"
                            id="comment"
                            cols="60"
                            rows="10"
                          />
                          <button className="btn btn-primary mt-4">
                            <Translate content="button.send" />
                          </button>
                        </form>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            ) : null }
            
            <div className="d-flex"></div>
          </div>
        </Fragment>
      );
    } else {
      return <Loading />;
    }
  }
}

function mapStateToProps(state) {
  return { 
    detail: state.applicationDetail, 
    errorMsg: state.error,
    language:state.language,
    registered_domain: state.auth.registered_domain,
    role: state.auth.role,
  };
}
export default compose(
  connect(mapStateToProps, {
    clearError,
    creditReqDetail,
    creditReqDetailEditor,
    changeApplicationStatus,
    changeApplicationStatusEditor,
    comment,
    commentEditor,
    downloadFile,
    changeApplicationMatchingStatusEditor,
  }),
  reduxForm({
    form: "comment"
  })
)(View);
