import client from "./index";
import { routes } from "./../_api/routes";

export const confirmationLink = (email, callback) => async dispatch => {
  try {
    const response = await client.post(routes.comfirmLink, { email });
    if (response) {
      callback();
    }
  } catch (e) {}
};
