import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Translate from "react-translate-component";
import { ToEuro } from "../../../utils/misc";
import { allCreditRequests } from "../../../actions/creditApplications";
import { allEditorCreditRequests } from "../../../actions/Editor/creditApplications";
import Moment from "react-moment";
import Pagination from "../../general/Pagination";
import Loading from "../../general/Loading";
class List extends Component {
  state = {
    applications: [],
    application_description: "",
    status: "",
    search_application_description: ""
  };
  componentDidMount() {
    this.allCreditRequests(1, this.state.status);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.applications !== prevProps.applications) {
      this.setState({
        applications: this.props.applications.list
      });
    }
    if (this.state.status !== prevState.status) {
      this.allCreditRequests(
        this.props.pagination.currentPage,
        this.state.status
      );
    }
  }
  
  allCreditRequests = (page, status, application_description) => {
    if(this.props.role === "admin") {
      return this.props.allCreditRequests(page, status, application_description);
    } else {
      return this.props.allEditorCreditRequests(page, status, application_description);
    }
    
  }

  renderApplications = applications => {
    if (applications.length === 0) {
      return (
        <tr>
          <td>
            <Translate content="message.no_application" />
          </td>
        </tr>
      );
    }
    return applications.map((app, index) => {
      const {
        creditor,
        amount,
        created_on,
        deadline,
        id,
        service,
        registered_domain,
        status
      } = app;
      return (
        <tr key={index}>
          <td className="res-p-l-1">
            <Link
              to={{
                pathname: "/creditor/application/detail",
                state: { id: id }
              }}
            >
              {creditor.full_name ? creditor.full_name : creditor }
            </Link>
          </td>
          <td>{service.name ? service.name[this.props.language] : null}</td>
          <td className="PH-text-right">
            <ToEuro amount={amount} />
          </td>
          <td className="PH-text-right">
            <Moment format="DD.MM.YYYY">{created_on}</Moment>{" "}
          </td>
          <td className="PH-text-right">
            <Moment format="DD.MM.YYYY">{deadline}</Moment>
          </td>
          <td className="PH-text-right">
            {registered_domain}
          </td>
          {status === "approved" ? (
            <td className="align-left clear-fix">
              {" "}
              <span className="badge badge-success float-right">
                <Translate content="label.approved" />
              </span>
            </td>
          ) : null}
          {status === "rejected" ? (
            <td className="align-left clear-fix">
              {" "}
              <span className="badge badge-danger float-rightspan badge badge-danger float-right">
                <Translate content="label.rejected" />
              </span>
            </td>
          ) : null}
          {status === "requested" ? (
            <td className="align-left clear-fix">
              {" "}
              <span className="badge badge-primary float-rightspan badge badge-primary float-right">
                <Translate content="label.requested" />
              </span>
            </td>
          ) : null}
          {status === "accepted" ? (
            <td className="align-left clear-fix">
              {" "}
              <span className="badge badge-open float-rightspan badge badge-open float-right">
                <Translate content="label.accepted" />
              </span>
            </td>
          ) : null}
          {status === "open" ? (
            <td className="align-left">
              <span className="badge badge-open">
                <Translate content="label.open" />
              </span>
            </td>
          ) : null}
          {status === "offer_accepted" ? (
            <td className="align-left">
              <span className="badge badge-warning">
                <Translate content="label.offer_accepted" />
              </span>
            </td>
          ) : null}
          {status === "offer_received" ? (
            <td className="align-left">
              <span className="badge badge-primary">
                <Translate content="label.offer_received" />
              </span>
            </td>
          ) : null}
          {status === "offer_rejected" ? (
            <td className="align-left">
              <span className="badge badge-dark">
                <Translate content="label.offer_rejected" />
              </span>
            </td>
          ) : null}
          {status === "questions_for_creditor" ? (
            <td className="align-left">
              <span className="badge badge-info">
                <Translate content="label.questions_for_creditor" />
              </span>
            </td>
          ) : null}
        </tr>
      );
    });
  };
  render() {
    const { totalPage } = this.props.pagination;
    return (
      <Fragment>
        <div className="content-head">
          <div className="content-head-left">
            <h1 className="content-head__title">
              <Translate content="sidebar.credit_requested_products" />
            </h1>
          </div>
          <div className="content-head-right">
            <Loading />
            <form className="form-filter" action="">
              <div className="row">
                <div className="col-3 col-sm-3 col-md-3 pr-0">
                  <div className="form-group">
                    <select
                      className="form-control"
                      name="select"
                      onChange={e => {
                        this.setState({
                          status: e.target.value
                        });
                      }}
                    >
                      <Translate
                        component="option"
                        value=""
                        attributes={{ label: "label.all" }}
                      />
                      <Translate
                        component="option"
                        value="approved"
                        attributes={{ label: "label.approved" }}
                      />
                      <Translate
                        component="option"
                        value="rejected"
                        attributes={{ label: "label.rejected" }}
                      />
                      <Translate
                        component="option"
                        value="requested"
                        attributes={{ label: "label.requested" }}
                      />
                      <Translate
                        component="option"
                        value="accepted"
                        attributes={{ label: "label.accepted" }}
                      />
                      <Translate
                        component="option"
                        value="open"
                        attributes={{ label: "label.open" }}
                      />
                      <Translate
                        component="option"
                        value="questions_for_creditor"
                        attributes={{ label: "label.questionsForCreditor" }}
                      />
                      <Translate
                        component="option"
                        value="offer_rejected"
                        attributes={{ label: "label.offerRejected" }}
                      />
                      <Translate
                        component="option"
                        value="offer_accepted"
                        attributes={{ label: "label.offerAccepted" }}
                      />
                    </select>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-6">
                  <div className="form-group">
                    <Translate
                      component="input"
                      className="form-control"
                      type="text"
                      name="search-text"
                      attributes={{ placeholder: "placeholder.search" }}
                      onChange={e =>
                        this.setState({
                          search_application_description: e.target.value
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-3 col-sm-3 col-md-3">
                  <div className="form-group">
                    <Translate
                      content="button.search"
                      component="button"
                      className="btn btn-primary col-12"
                      onClick={e => {
                        e.preventDefault();
                        (async () => {
                          await this.setState({
                            application_description: this.state
                              .search_application_description
                          });
                          this.allCreditRequests(
                            1,
                            this.state.status,
                            this.state.application_description
                          );
                        })();
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="content-body">
          <table
            className="table tablesaw-stack"
            data-tablesaw-mode="stack"
            data-tablesaw-minimap="data-tablesaw-minimap"
          >
            <thead>
              <tr>
                <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                  <Translate content="sidebar.creditor" />
                </th>
                <th
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  data-tablesaw-priority="persist"
                  scope="col"
                >
                  <Translate content="column.services" />
                </th>
                <th
                  className=""
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.requestedamount" />
                </th>
                <th
                  className=""
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.requeston" />
                </th>
                <th
                  className=""
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="label.deadline" />
                </th>
                <th
                  className=""
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.domain" />
                </th>
                <th
                  className="text-right"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>{this.renderApplications(this.state.applications)}</tbody>
          </table>
          <Pagination
            totalPage={totalPage}
            url={page =>
              this.allCreditRequests(
                page,
                this.state.status,
                this.state.application_description
              )
            }
          />
        </div>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    applications: state.creditRequests,
    pagination: state.pagination,
    language: state.language,
    role: state.auth.role,
  };
}

export default connect(mapStateToProps, { allCreditRequests, allEditorCreditRequests })(List);
