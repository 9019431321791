import React, { Component, Fragment, input } from "react";
import { connect } from "react-redux";
import {
  getMatchedList,
  createMatch
} from "../../../actions/creditApplications";
import {
  getMatchedListEditor,
  createMatchEditor
} from "../../../actions/Editor/creditApplications";
import { selectElement, ToEuro } from "../../../utils/misc";
import Translate from "react-translate-component";
import Loading from "../../general/Loading";
class MatchedList extends Component {
  state = {
    matchedList: [],
    selectedId: [],
    product_title: "",
    search_product_title: ""
  };
  componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.push("/creditor/credit-requests");
    } else {
      const { id } = this.props.location.state;
      this.getMatchedList(id);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.matchedList !== this.props.matchedList) {
      this.setState({
        matchedList: this.props.matchedList.list
      });
    }
  }
  getMatchedList(id, product_title) {
    if(this.props.role === "admin") {
      return this.props.getMatchedList(id, product_title);
    } else {
      return this.props.getMatchedListEditor(id, product_title);
    }
  }
  showList = matchedProducts => {
    if (matchedProducts.length === 0) {
      return <span>No Matched Products Found </span>;
    } else {
      return matchedProducts.map((product, index) => {
        const {
          id,
          industries,
          max_credit_amount,
          min_credit_amount,
          product_title,
          service,
          registered_domain,
          states
        } = product;
        return (
          <tr key={index}>
            <td>
              <input
                type="checkbox"
                name="first"
                value={id}
                onChange={e => this.onSelect(parseInt(e.target.value))}
              />
            </td>
            <td> {product_title}</td>
            <td>
              {industries && Array.isArray(industries)
                ? this.showArrayElements(industries)
                : null}
            </td>
            <td> {service ? service[this.props.language] : null}</td>
            <td>
              {states && Array.isArray(states)
                ? this.showArrayElements(states)
                : null}
            </td>
            <td> {registered_domain}</td>
            <td className="text-right">
              <ToEuro amount={min_credit_amount} />
            </td>
            <td className="text-right">
              <ToEuro amount={max_credit_amount} />
            </td>
          </tr>
        );
      });
    }
  };
  showArrayElements = array => {
    if (array.lenth === 0) {
      return "Not Available";
    } else {
      return array.map((arr, index) => {
        return (
          <span>
            {" "}
            {arr.name ? arr.name[this.props.language] : null}
            <br />
          </span>
        );
      });
    }
  };
  onSelect = id => {
    var selected = selectElement(this.state.selectedId, id);
    this.setState({
      selectedId: selected
    });
  };
  onSubmit = () => {
    if(this.props.role === "admin") {
      this.props.createMatch(
        this.props.location.state.id,
        this.state.selectedId,
        () => {
          this.props.history.push("/creditor/credit-requests");
        }
      );
    } else {
      this.props.createMatchEditor(
        this.props.location.state.id,
        this.state.selectedId,
        () => {
          this.props.history.push("/creditor/credit-requests");
        }
      );
    }
    
  };
  render() {
    return (
      <Fragment>
        <div className="content-head">
          <div className="content-head-left">
            <h1 className="content-head__title">
              <Translate content="label.matchedProductForRequest" />
            </h1>
          </div>
          <div className="content-head-right">
            <Loading />
            <form className="form-filter d-flex" action="">
              {/* <div className="item mr-3">
                <div className="form-group">
                  <select className="form-control" name="select">
                    <option value="all">Status</option>
                  </select>
                </div>
              </div> */}
              <div className="item mr-3">
                <div className="form-group">
                  <Translate
                    className="form-control"
                    type="text"
                    name="search-text"
                    attributes={{ placeholder: "placeholder.search" }}
                    onChange={e =>
                      this.setState({ search_product_title: e.target.value })
                    }
                  />
                  <Translate
                    content="button.search"
                    component="button"
                    className="btn btn-primary"
                    onClick={e => {
                      e.preventDefault();
                      (async () => {
                        await this.setState({
                          product_title: this.state.search_product_title
                        });
                        this.getMatchedList(1, this.state.product_title);
                      })();
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="content-body">
          <table
            className="table tablesaw-stack"
            data-tablesaw-mode="stack"
            data-tablesaw-minimap="data-tablesaw-minimap"
          >
            <thead>
              <tr>
                <th></th>
                <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                  <Translate content="column.name" />
                </th>
                <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                  <Translate content="column.industry" />
                </th>
                <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                  <Translate content="label.creditType" />
                </th>
                <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                  <Translate content="label.county" />
                </th>
                <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                  <Translate content="column.domain" />
                </th>
                <th
                  className="text-right"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                >
                  <Translate content="label.minCreditAmount" />
                </th>
                <th
                  className="text-right"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                >
                  <Translate content="label.maxCreditAmount" />
                </th>
              </tr>
            </thead>
            <tbody>{this.showList(this.state.matchedList)}</tbody>
          </table>
        </div>
        {this.state.selectedId.length > 0 ? (
          <div className="content-footer">
            <button
              className="btn btn-primary"
              type="submit"
              onClick={this.onSubmit}
            >
              Confirm
            </button>
          </div>
        ) : null}
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return { 
    matchedList: state.matchedList,language:state.language,
    role: state.auth.role,
  };
}
export default connect(mapStateToProps, { getMatchedList, getMatchedListEditor, createMatch, createMatchEditor })(
  MatchedList
);
