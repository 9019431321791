import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Multiselect from "react-widgets/lib/Multiselect";
import "react-widgets/dist/css/react-widgets.css";
import Translate from "react-translate-component";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InputFieldError from "../error/InputFieldError";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import RDropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
export const inputField = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  readOnly,
  // initialValues,
  meta: { error, touched }
}) => {
  return (
    <div>
      {/* {label ? <label>{label}</label> : null} */}
      <Translate
        {...input}
        type={type}
        component="input"
        color={"white"}
        className={className}
        id={id}
        attributes={{ placeholder: placeholder }}
        // value= {initialValues}
        readOnly={readOnly}
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </div>
  );
};

export const checkBox = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  meta: { error, touched }
}) => {
  return (
    <div className="form-check">
      <input
        {...input}
        type={type}
        color={"white"}
        className={className}
        id={id}
        placeholder={placeholder}
      />
      <label className="form-check-label">
        <Translate content="label.iAgreeToThe" />{" "}
        <Link to="/terms">
          {" "}
          <Translate content="label.termsAndConditions" />
        </Link>
      </label>
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </div>
  );
};

export const dropDownField = ({
  options,
  input,
  label,
  type,
  className,
  id,
  placeholder,
  meta: { error, touched }
}) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      <Select
        {...input}
        onChange={value => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        options={options}
        className={className}
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </div>
  );
};

export const inputSlider = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  meta: { error, touched }
}) => {
  return (
    <Fragment>
      <div className="col col-10">
        <label className="d-block mincredit-amount mb-5" for="mincredit-amount">
          {label}
        </label>
        <input
          {...input}
          color={"white"}
          className={className}
          id={id}
          placeholder={placeholder}
          type={type}
          min="0"
          max="999999"
          step="1"
          data-orientation="horizontal"
        />
      </div>

      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </Fragment>
  );
};

export const renderMultiselect = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  meta: { error, touched },
  data,
  valueField,
  textField
}) => {
  return (
    <Fragment>
      <label for="">{label}</label>
      <Multiselect
        {...input}
        onBlur={() => input.onBlur()}
        value={input.value || []}
        data={data}
        valueField={valueField}
        textField={textField}
        className={className}
        id={id}
        placeholder="Select Tags"
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </Fragment>
  );
};
export const textAreaField = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  cols,
  rows,
  meta: { error, touched }
}) => {
  return (
    <div>
      <label>{label}</label>
      <textarea
        {...input}
        type={type}
        color={"white"}
        className={className}
        id={id}
        placeholder={placeholder}
        cols={cols}
        rows={rows}
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </div>
  );
};

export const renderPhone = ({
  label,
  input: { onChange, value },
  inputExtraProps: { name, required },
  regions,
  placeholder,
  country,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <div>
      <PhoneInput
        inputExtraProps={{
          name: name,
          required: required
        }}
        onChange={onChange}
        value={value}
        regions={regions}
        placeholder={placeholder}
        country={country}
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </div>
  </div>
);

export const ReactUploader = ({ input, meta: { touched, error } }) => {
  let uploadedFiles = [];
  const handleChangeStatus = files => {
    let alreadyExists = false;
    uploadedFiles.forEach(uploadedFile => {
      if (uploadedFile.name === files.file.name) {
        alreadyExists = true;
      }
    });
    if (!alreadyExists) {
      uploadedFiles.push(files.file);
    }
    input.onChange(uploadedFiles);
  };

  const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles }
  }) => {
    return (
      <div>
        <div {...dropzoneProps}>
          <div className="dropzone-preview">{previews}</div>
          {files.length < maxFiles && input}
        </div>
      </div>
    );
  };

  //   const Preview = ({ meta }) => {
  //     const { name, percent, status } = meta;
  //     return (
  //       <span
  //         style={{
  //           alignSelf: "flex-start",
  //           margin: "10px 3%",
  //           fontFamily: "Helvetica"
  //         }}
  //       >
  //         Hello
  //         {name}, {Math.round(percent)}%, {status}
  //       </span>
  //     );
  //   };

  const CustomInput = ({ accept, files, onFiles, getFilesFromEvent }) => {
    const text =
      files.length > 0
        ? "Add file or drop files here"
        : "Add file or drop files here";

    return (
      <label className="fileupload-label">
        <div className="fileupload-label-content">
          <img src="/assets/img/icons/bx-cloud-upload.png" alt="" />
          {text}
        </div>

        <input
          style={{ display: "none" }}
          type="file"
          accept={accept}
          multiple
          onChange={e => {
            getFilesFromEvent(e).then(chosenFiles => {
              onFiles(chosenFiles);
            });
          }}
        />
      </label>
    );
  };

  const getFilesFromEvent = e => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
        resolve(chosenFiles.map(f => f.fileObject));
      });
    });
  };

  return (
    <div>
      <RDropzone
        onChangeStatus={handleChangeStatus}
        autoUpload={false}
        LayoutComponent={Layout}
        InputComponent={CustomInput}
        getFilesFromEvent={getFilesFromEvent}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            {console.log(...getRootProps())}
            <input {...getInputProps()} />
          </div>
        )}
      </RDropzone>
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </div>
  );
};
