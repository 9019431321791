import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./App";
import reducers from "./reducers";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// Higer Order Component
import RequireAuth from "./components/hoc/RequireAuth";
import RequireNoAuth from "./components/hoc/RequireNoAuth";
// AUTH RELATED COMPONENT
import Login from "./components/auth/Login";
// CREDITOR RELATED COMPONENTS
import ListAccounts from "./components/creditor/ListAccount";
import List from "./components/creditor/applications/List";
import View from "./components/creditor/applications/View";
import UserDetail from "./components/creditor/UserDetail";
import AccountRequest from "./components/creditor/AccountRequest";
import MatchedList from "./components/creditor/applications/MatchedList";
// INVESTOR RELATED COMPONENT
import ListAccount from "./components/investor/ListAccounts";
import AllProducts from "./components/investor/products/AllProducts";
import ProductDetail from "./components/investor/products/ProductDetail";
import RequestedProduct from "./components/investor/products/RequestedProduct";
import RequestedAccounts from "./components/investor/RequestedAccounts";
import AddAccount from "./components/investor/AddAccount";
// ADMIN RELATED COMPONENTS
import AddAdmin from "./components/admin/AddAdmin";
import ListAdmins from "./components/admin/ListAdmins";
import rating from "./components/setting/rating";
import addrating from "./components/setting/addrating";
import EditProfile from "./components/user/EditProfile";
import ViewProfile from "./components/user/ViewProfile";

import Notifications from "./components/notifications/List";
import ChangePassword from "./components/user/ChangePassword";
import PasswordChangeSuccess from "./components/general/PasswordChangeSuccess";
// Design Component
import popup from "./components/general/popup";
import states from "./components/setting/states";
import addstate from "./components/setting/addstate";
import detailState from "./components/setting/detailState";
import editstate from "./components/setting/editstate";
import emailtemplate from "./components/setting/emailtemplate";
import services from "./components/setting/services";
import addservices from "./components/setting/addservices";
import industries from "./components/setting/industries";
import addindustries from "./components/setting/addindustries";
import Error404 from "./components/general/Error404";
import editemailtemplate from "./components/setting/editemailtemplate";
import AddEditor from "./components/editor/AddEditor";
import ListEditors from "./components/editor/ListEditors";
import RequireAdminAuth from "./components/hoc/RequireAdminAuth";

const store = createStore(
  reducers,
  {
    auth: {
      authenticated: localStorage.getItem("token"),
      role: localStorage.getItem("role"),
      registered_domain: localStorage.getItem("registered_domain"),
    }
  },
  applyMiddleware(reduxThunk)
);

Sentry.init({
  dsn: "https://8279396e2a034635bcecac7f97a346d9@o524465.ingest.sentry.io/5839984",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.7,
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={RequireNoAuth(Login)} />
        <Route
          path="/change-password"
          component={RequireAuth(ChangePassword)}
        />
        <Route
          path="/password-change-success"
          component={PasswordChangeSuccess}
        />

        {/* All creditor related links */}
        <Route
          exact
          path="/creditor/accounts"
          component={RequireAuth(App(ListAccounts))}
        />
        <Route path="/user/detail" component={RequireAuth(App(UserDetail))} />
        <Route
          path="/creditor/add-account"
          component={RequireAuth(App(AddAccount))}
        />
        <Route
          path="/creditor/credit-requests"
          component={RequireAuth(App(List))}
        />
        <Route
          path="/creditor/application/detail"
          component={RequireAuth(App(View))}
        />
        <Route
          exact
          path="/creditor/accounts/requested"
          component={RequireAuth(App(AccountRequest))}
        />
        <Route
          path="/creditor/matched-products"
          component={RequireAuth(App(MatchedList))}
        />
        {/* All Investor related links */}
        <Route
          path="/investor/accounts"
          component={RequireAuth(App(ListAccount))}
        />
        <Route
          exact
          path="/investor/requested-accounts"
          component={RequireAuth(App(RequestedAccounts))}
        />
        <Route
          exact
          path="/investor/products"
          component={RequireAuth(App(AllProducts))}
        />
        <Route
          exact
          path="/investor/requested-products"
          component={RequireAuth(App(RequestedProduct))}
        />
        <Route
          path="/investor/product/detail"
          component={RequireAuth(App(ProductDetail))}
        />
        <Route
          path="/investor/add-account"
          component={RequireAuth(App(AddAccount))}
        />
        {/* Editor RELATED ROUTES */}
        <Route path="/editor/add-editor" component={RequireAuth(App(AddEditor))} />
        <Route path="/editor/list" component={RequireAuth(App(ListEditors))} />

        {/* ADMIN RELATED ROUTES */}
        <Route path="/admin/add-admin" component={RequireAdminAuth(App(AddAdmin))} />
        <Route path="/admin/list" component={RequireAdminAuth(App(ListAdmins))} />
        {/* Adim profile routes */}
        <Route
          exact
          path="/user/edit-profile"
          component={RequireAuth(App(EditProfile))}
        />
        <Route
          exact
          path="/user/profile"
          component={RequireAuth(App(ViewProfile))}
        />

        <Route
          exact
          path="/notification"
          component={RequireAuth(App(Notifications))}
        />
        {/* DESIGN COMPONENT ROUTES */}
        <Route path="/rating" component={RequireAdminAuth(App(rating))} />
        <Route path="/addrating" component={RequireAdminAuth(App(addrating))} />
        <Route path="/popup" component={RequireAdminAuth(App(popup))} />
        <Route path="/states" component={RequireAdminAuth(App(states))} />
        <Route path="/addstate" component={RequireAdminAuth(App(addstate))} />
        <Route path="/detailstate" component={RequireAdminAuth(App(detailState))} />
        <Route path="/editstate" component={RequireAdminAuth(App(editstate))} />
        <Route path="/emailtemplate" component={RequireAdminAuth(App(emailtemplate))} />
        <Route path="/editemailtemplate" component={RequireAdminAuth(App(editemailtemplate))} />
        <Route path="/services" component={RequireAdminAuth(App(services))} />
        <Route path="/addservices" component={RequireAdminAuth(App(addservices))} />

        <Route path="/industries" component={RequireAdminAuth(App(industries))} />
        <Route path="/addindustries" component={RequireAdminAuth(App(addindustries))} />

        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.querySelector("#root")
);
