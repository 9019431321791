import React, { Component, Fragment } from "react";
import Pagination from "../../general/Pagination";
import { allProducts } from "../../../actions/product";
import { allProductsEditor } from "../../../actions/Editor/product";
import Translate from "react-translate-component";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ToEuro } from "../../../utils/misc";


class ProductList extends Component {
  state = {
    products: []
  };

  componentDidMount() {
    this.allProducts(
      1,
      false,
      this.props.status,
      null,
      this.props.investor_id
    );
  }

  allProducts = (
    page,
    requested,
    status,
    product_title,
    investor_id
  ) => {
    if(this.props.role === "admin") {
      return this.props.allProducts(page,requested,status,product_title,investor_id);
    } else {
      return this.props.allProductsEditor(page,requested,status,product_title,investor_id);
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.products !== this.props.products) {
      this.setState({ products: this.props.products.list });
    }
    if (prevProps.status !== this.props.status) {
      this.allProducts(
        this.props.pagination.currentPage,
        false,
        this.props.status
      );
    }
  }

  showProducts = products => {
    if (products.length === 0) {
      return (
        <tr>
          <td>
            <Translate content="message.no_product" />
          </td>
        </tr>
      );
    } else {
      return products.map((product, index) => {
        const {
          id,
          max_credit_amount,
          min_credit_amount,
          product_title,
          service,
          status,
          registered_domain,
          investor
        } = product;

        return (
          <tr key={index}>
            <td className="res-p-l-1">
              {" "}
              <Link
                to={{
                  pathname: "/investor/product/detail",
                  state: { id: id }
                }}
              >
                {product_title}
              </Link>
            </td>
            <td>
              {service.name ? service.name[this.props.language] : null}
            </td>
            <td>
              <ToEuro amount={max_credit_amount} />
            </td>
            <td>
              <ToEuro amount={min_credit_amount} />
            </td>
            <td>{investor}</td>
            <td>{registered_domain}</td>
            {status === "accepted" ? (
              <td className="align-left">
                <span className="badge badge-success">
                  <Translate content="label.accepted" />
                </span>
              </td>
            ) : null}
            {status === "new" ? (
              <td className="align-left">
                <span className="badge badge-primary">
                  <Translate content="label.new" />
                </span>
              </td>
            ) : null}
            {status === "rejected" ? (
              <td className="align-left">
                <span className="badge badge-danger">
                  {" "}
                  <Translate content="label.rejected" />
                </span>
              </td>
            ) : null}
            {status === "open" ? (
              <td className="align-left">
                <span className="badge badge-open">
                  {" "}
                  <Translate content="label.open" />
                </span>
              </td>
            ) : null}
          </tr>
        );
      });
    }
  };

  render() {
    const { totalPage } = this.props.pagination;
    return (
      <Fragment>
        <div className="content-body">
          <table
            className="table tablesaw-stack"
            data-tablesaw-mode="stack"
            data-tablesaw-minimap="data-tablesaw-minimap"
          >
            <thead>
              <tr>
                <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                  <Translate content="column.name" />
                </th>
                <th
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  data-tablesaw-priority="persist"
                  scope="col"
                >
                  <Translate content="column.services" />
                </th>
                <th
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.max_credit_amount" />
                </th>
                <th
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.minimum_credit_amount" />
                </th>
                <th
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="sidebar.investor" />
                </th>
                <th
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.domain" />
                </th>
                <th
                  className="text-right-piehub-table"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>{this.showProducts(this.state.products)}</tbody>
          </table>
          <Pagination
            totalPage={totalPage}
            url={page => this.allProducts(page, false, this.props.status)}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { 
    products: state.products,
    pagination: state.pagination,
    language:state.language,
    role: state.auth.role,
 };
}

export default connect(mapStateToProps, { allProducts, allProductsEditor })(ProductList);
