export const AUTH_USER = "auth_user";
export const AUTH_USER_LOGOUT = 'LOGOUT';
export const ERROR = "error";
export const ALL_USER_ACCOUNT = "all_user_account";
export const USER_DETAIL = "user_detail";
export const ALL_CREDIT_APPLICATIONS = "all_credit_applications";
export const APPLICATION_DETAIL = "application_detail";
export const ALL_PRODUCTS = "all_products";
export const PRODUCT_DETAIL = "product_detail";
export const CHANGE_LANGUAGE = "change_language";
export const MATCHED_LIST = "matched_list";
export const PAGINATION = "pagination";
export const LOADING = "loading";
export const LOADING_DONE = "loading_done";
export const CLEAR_LOADING = "clear_loading";
export const GET_PROFILE = "get_profile";
export const NOTIFICATION_COUNT = "notification_count";
export const NOTIFICATION_LIST = "notification_list";
export const SHOW_FLASH_MESSAGE = "show_flash_message";
export const HIDE_FLASH_MESSAGE = "hide_flash_message";

