import client from "../indexEditor";
import { routes } from "../../_api/routesEditor";
import {
  ERROR,
  ALL_USER_ACCOUNT,
  USER_DETAIL,
  PAGINATION,
  LOADING,
  LOADING_DONE
} from "../types";
export const getAllUsersEditor = (
  page,
  role,
  requested,
  status,
  search_user
) => async dispatch => {
  try {
    dispatch({
      type: LOADING,
      payload: {
        URL: `${routes.allUsers}`,
        method: "get",
        data: { page, role, requested, status, search_user }
      }
    });
    let response;
    if (status && search_user) {
      response = await client.get(
        `${routes.allUsers}?page=${page}&role=${role}&status=${status}&search_user=${search_user}`
      );
    } else if (requested && search_user) {
      response = await client.get(
        `${routes.allUsers}?page=${page}&role=${role}&requested=${requested}&search_user=${search_user}`
      );
    } else if (requested) {
      response = await client.get(
        `${routes.allUsers}?page=${page}&role=${role}&requested=${requested}`
      );
    } else if (status) {
      response = await client.get(
        `${routes.allUsers}?page=${page}&role=${role}&status=${status}`
      );
    } else if (search_user) {
      response = await client.get(
        `${routes.allUsers}?page=${page}&role=${role}&search_user=${search_user}`
      );
    } else {
      response = await client.get(
        `${routes.allUsers}?page=${page}&role=${role}`
      );
    }
    dispatch({
      type: ALL_USER_ACCOUNT,
      payload: response.data.data
    });
    dispatch({
      type: PAGINATION,
      payload: response.data.meta
    });
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.allUsers}`,
        method: "get",
        data: { page, role, requested, status, search_user }
      }
    });
  } catch (e) {
    // dispatch({
    //   type: ERROR,
    //   payload: `${e.response.data.error}.`
    // });
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.allUsers}`,
        method: "get",
        data: { page, role, requested, status }
      }
    });
  }
};

export const userDetailEditor = id => async dispatch => {
  dispatch({
    type: LOADING,
    payload: { URL: `${routes.allUsers}`, method: "get", data: { id } }
  });
  try {
    const response = await client.get(`${routes.allUsers}/${id}`);
    dispatch({
      type: USER_DETAIL,
      payload: response.data.data
    });
    dispatch({
      type: LOADING_DONE,
      payload: { URL: `${routes.allUsers}`, method: "get", data: { id } }
    });
  } catch (e) {
    dispatch({
      type: LOADING_DONE,
      payload: { URL: `${routes.allUsers}`, method: "get", data: { id } }
    });
  }
};

export const changeUserStatusEditor = (
  id,
  status,
  role,
  callback
) => async dispatch => {
  try {
    const response = await client.put(
      `${routes.allUsers}/${id}/status`,
      { status },
      {
        headers: {
          role: role
        }
      }
    );
    if (response) {
      callback();
    }
  } catch (e) {}
};

export const addUserEditor = (detail, callback1, callback2) => async dispatch => {
  try {
    dispatch({
      type: LOADING,
      payload: {
        URL: `${routes.allUsers}?role=${detail.type}`,
        method: "post",
        data: { detail }
      }
    });
    const response = await client.post(
      `${routes.allUsers}?role=${detail.type}`,
      detail
    );
    if (response) {
      dispatch({
        type: LOADING_DONE,
        payload: {
          URL: `${routes.allUsers}?role=${detail.type}`,
          method: "post",
          data: { detail }
        }
      });
      detail.type === "creditor" ? callback2() : callback1();
    }
  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e.response.data.errors
    });
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.allUsers}?role=${detail.type}`,
        method: "post",
        data: { detail }
      }
    });
  }
};
export const addAdmin = (detail, callback) => async dispatch => {
  try {
    dispatch({
      type: LOADING,
      payload: {
        URL: `${routes.allUsers}?role=${detail.type}`,
        method: "post",
        data: detail
      }
    });
    let body = new FormData();
    body.set("fname", detail.fname);
    body.set("lname", detail.lname);
    body.set("email", detail.email);
    body.set("password", detail.password);
    body.set("password_confirmation", detail.password_confirmation);
    body.set("phone_number", detail.phone_number);

    const response = await client.post(
      `${routes.allUsers}?role=${detail.type}`,
      detail
    );
    if (response) {
      dispatch({
        type: LOADING_DONE,
        payload: {
          URL: `${routes.allUsers}?role=${detail.type}`,
          method: "post",
          data: detail
        }
      });
      callback();
    }
  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e.response.data.errors
    });
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.allUsers}?role=${detail.type}`,
        method: "post",
        data: detail
      }
    });
  }
};

export const updateApplicationLimitEditor = (id, application_limit, callback) => async dispatch => {
  try{
    dispatch({
      type:LOADING,
      payload:{}
    });
    const response = await client.put(
      `${routes.allUsers}/${id}/application-limit`,
      { application_limit: application_limit }
    );
    return response;
  }catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:{message:e.response.data.message ? e.response.data.message:null, errors:e.response.data.errors}
      });
    }
  } finally {
    dispatch({
      type:LOADING_DONE,
      payload:{}
    });
  }
}

export const addEditorAsEditor = (detail, callback) => async dispatch => {
  try {
    dispatch({
      type: LOADING,
      payload: {
        URL: `${routes.allUsers}?role=${detail.type}`,
        method: "post",
        data: detail
      }
    });
    let body = new FormData();
    body.set("fname", detail.fname);
    body.set("lname", detail.lname);
    body.set("email", detail.email);
    body.set("password", detail.password);
    body.set("password_confirmation", detail.password_confirmation);
    body.set("phone_number", detail.phone_number);
    if(detail.registered_domain && detail.registered_domain !== undefined) {
      body.set("registered_domain", detail.registered_domain);
    }

    const response = await client.post(
      `${routes.allUsers}?role=${detail.type}`,
      detail
    );
    if (response) {
      dispatch({
        type: LOADING_DONE,
        payload: {
          URL: `${routes.allUsers}?role=${detail.type}`,
          method: "post",
          data: detail
        }
      });
      callback();
    }
  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e.response.data.errors
    });
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.allUsers}?role=${detail.type}`,
        method: "post",
        data: detail
      }
    });
  }
};
