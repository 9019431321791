import React, { Component } from "react";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";

export default ChildComponent => {
  class ComposedComponent extends Component {
    componentDidMount() {
      this.shouldNavigateAway();
    }

    componentDidUpdate() {
      this.shouldNavigateAway();
    }

    shouldNavigateAway() {
      // give false condition
      if (!this.props.auth && this.props.role !== "admin") {
        localStorage.setItem("redirect", this.props.location.pathname);
        this.props.history.push("/");
      } else {
        const { exp } = jwt.decode(this.props.auth);
        if (exp * 1000 < new Date().getTime()) {
          localStorage.removeItem("token");
          localStorage.removeItem("registered_domain");
          localStorage.removeItem("role");
          this.props.history.push("/");
        }
      }
    }

    render() {
      return <ChildComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      auth: state.auth.authenticated,
      role: state.auth.role
    };
  }

  return connect(mapStateToProps)(ComposedComponent);
};
