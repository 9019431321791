import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class editstate extends Component {
  render() {
    return [
      <div className="content-head">
        <div className="content-head-left">
          <h1 className="content-head__title">Edit State</h1>
        </div>
      </div>,

      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <div>
              <label>
                <span>State Name</span>
              </label>
              <input
                className="form-control"
                type="text"
                value="Baden-Württemberg"
              ></input>
            </div>
          </div>
          <div className="form-group">
            <strong className="d-block">Status</strong>
            <div className="form-check form-check-inline pt-3">
              <div>
                <input
                  type="radio"
                  id="status-active"
                  className="form-check-input"
                  name="status"
                  checked
                ></input>

                <label className="form-check-label" for="status-active">
                  Active
                </label>
              </div>

              <div className="ml-4">
                <input
                  type="radio"
                  id="status-inactive"
                  className="form-check-input"
                  name="status"
                ></input>

                <label className="form-check-label" for="status-inactive">
                  Inactive
                </label>
              </div>
            </div>
          </div>

          <button className="btn btn-primary mt-2">Update</button>
        </div>
      </div>,
      <hr className="mt-5" />,
      <div className="county mt-5">
        <div className="content-head">
          <div className="content-head-left">
            <h1 className="content-head__title">List of County</h1>
          </div>

          <div className="content-head-right">
            <Link className="btn btn-primary" to="">
              Add new county
            </Link>
          </div>
        </div>
        <table
          className="table tablesaw-stack"
          data-tablesaw-mode="stack"
          data-tablesaw-minimap="data-tablesaw-minimap"
        >
          <thead>
            <tr>
              <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                County Name
              </th>
              <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                Status
              </th>
              <th
                className="text-right PH-text-right"
                data-tablesaw-sortable-col="data-tablesaw-sortable-col"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="res-p-l-1">
                <Link to="#">Alb-Donau-Kreis</Link>
              </td>
              <td>Active</td>
              <td>
                <div className="text-right PH-text-right">
                  <Link
                    to="#"
                    className="d-inline-flex mr-2 align-items-center"
                  >
                    <i class="bx bxs-edit"></i>
                    Edit
                  </Link>
                  <Link to="#" className="d-inline-flex align-items-center">
                    <i class="bx bxs-trash-alt"></i>
                    Remove
                  </Link>
                </div>
              </td>
            </tr>

            <tr>
              <td className="res-p-l-1">
                <Link to="#">Baden-Baden</Link>
              </td>
              <td>Active</td>
              <td>
                <div className="text-right PH-text-right">
                  <Link
                    to="#"
                    className="d-inline-flex mr-2 align-items-center"
                  >
                    <i class="bx bxs-edit"></i>
                    Edit
                  </Link>
                  <Link to="#" className="d-inline-flex align-items-center">
                    <i class="bx bxs-trash-alt"></i>
                    Remove
                  </Link>
                </div>
              </td>
            </tr>

            <tr>
              <td className="res-p-l-1">
                <Link to="#">Biberach</Link>
              </td>
              <td>Active</td>
              <td>
                <div className="text-right PH-text-right">
                  <Link
                    to="#"
                    className="d-inline-flex mr-2 align-items-center"
                  >
                    <i class="bx bxs-edit"></i>
                    Edit
                  </Link>
                  <Link to="#" className="d-inline-flex align-items-center">
                    <i class="bx bxs-trash-alt"></i>
                    Remove
                  </Link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="content-footer">
          <nav class="nav-content">
            <ul class="pagination">
              <li class="page-item">
                <button class="page-link">
                  <i class="bx bx-chevron-left"></i>
                </button>
              </li>
              <li class="page-item active">
                <button class="page-link">1</button>
              </li>
              <li class="page-item">
                <button class="page-link">2</button>
              </li>
              <li class="page-item">
                <button class="page-link">3</button>
              </li>
              <li class="page-item">
                <button class="page-link">4</button>
              </li>
              <li class="page-item">
                <button class="page-link">
                  <i class="bx bx-chevron-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    ];
  }
}
