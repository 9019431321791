import React, { Component } from "react";
import { connect } from "react-redux";
import Translate from "react-translate-component";
import { withRouter, Link } from "react-router-dom";
import counterpart from "counterpart";
import { logout } from "../../actions/login";
import { changeLanguage } from "../../actions/changeLanguage";
import { getNotificationCount } from "../../actions/notifications";
import en from "../../language/en";
import de from "../../language/de";
import { SidebarContext } from "./SidebarContext";

counterpart.registerTranslations("en", en);
counterpart.registerTranslations("de", de);
counterpart.setLocale(
  localStorage.getItem("language") || navigator.language.split("-")[0] || "de"
);
class Header extends Component {
  componentDidMount() {
    this.props.getNotificationCount();
  }
  onClick = lang => {
    counterpart.setLocale(lang);
    this.props.changeLanguage(lang);
  };

  static contextType = SidebarContext;

  render() {
    return (
      <header className="site-header align-items-center">
        <div className="logo-container">
          <div id="hamburger" onClick={this.context.toggleSidebar}>
            <div className="hamburger-bar"></div>
          </div>
          <Link to="/">
              <img src="/assets/img/logo.png" alt="PiHub-Logo" />
          </Link>
          
        </div>
        <nav className="header-actions">
          <ul className="language-changer">
            <li>
              <button onClick={() => this.onClick("en")}>
                <img src="/assets/img/gb.svg" alt="English Language" />
                English
              </button>
            </li>
            <li>
              <button onClick={() => this.onClick("de")}>
                <img src="/assets/img/de.svg" alt="Deutsch Language" />
                Deutsch
              </button>
            </li>
            <li className="header-actions__item dropdown notification">
              <Link className="header-notification" to="/notification">
                <i className="bx bx-bell" />
                <span className="notification-count">{this.props.count}</span>
              </Link>
            </li>
            <li className="header-actions__item header-dropdown">
              <Link
                className="header-user-dropdown"
                id="dropDownnMenuButtonUser"
                to=""
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img src="/assets/img/user.png" alt="John Doe" />
                <i className="bx bx-chevron-down"></i>
              </Link>
              <div
                className="dropdown-menu dropdown-menu-right dropdown-menu-user p-0"
                aria-labelledby="dropDownnMenuButtonUser"
              >
                <div className="dropdown-container">
                  <ul className="notification p-0">
                    <Link className="dropdown-item" to="/user/profile">
                      <Translate content="label.profile" />
                    </Link>
                    <Link className="dropdown-item" to="/user/edit-profile">
                      <Translate content="label.editprofile" />
                    </Link>
                    <Link className="dropdown-item" to="/change-password">
                      <Translate content="label.resetpassword" />
                    </Link>
                    <span
                      className="dropdown-item"
                      onClick={() => {
                        this.props.logout(() => this.props.history.push("/"));
                      }}
                    >
                      <Translate content="label.logout" />
                    </span>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

function mapStatesToProps(state) {
  return { count: state.notificationCount, language: state.language };
}

export default connect(mapStatesToProps, {
  logout,
  changeLanguage,
  getNotificationCount
})(withRouter(Header));
