import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class detailState extends Component {
  render() {
    return [
      <div className="content-head">
        <div className="content-head-left">
          <h1 className="content-head__title">State Detail</h1>
        </div>
      </div>,
      <div className="content-body">
        <div className="state-details row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="row">
              <div className="col-4">
                <h6>State Name</h6>
                <p>Baden-Württemberg</p>
              </div>

              <div className="col-4">
                <h6>Status</h6>
                <p>Active</p>
              </div>
            </div>
          </div>
        </div>
      </div>,
      <hr className="mt-5" />,
      <div className="county mt-5">
        <div className="content-head">
          <div className="content-head-left">
            <h1 className="content-head__title">List of County</h1>
          </div>

          <div className="content-head-right">
            <Link className="btn btn-primary" to="">
              Add new county
            </Link>
          </div>
        </div>
        <table
          className="table tablesaw-stack"
          data-tablesaw-mode="stack"
          data-tablesaw-minimap="data-tablesaw-minimap"
        >
          <thead>
            <tr>
              <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                County Name
              </th>
              <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                Status
              </th>
              <th
                className="text-right PH-text-right"
                data-tablesaw-sortable-col="data-tablesaw-sortable-col"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="res-p-l-1">Alb-Donau-Kreis</td>
              <td>Active</td>
              <td>
                <div className="text-right PH-text-right">
                  <Link
                    to="#"
                    className="d-inline-flex mr-2 align-items-center"
                  >
                    <i class="bx bxs-edit"></i>
                    Edit
                  </Link>
                  <Link to="#" className="d-inline-flex align-items-center">
                    <i class="bx bxs-trash-alt"></i>
                    Remove
                  </Link>
                </div>
              </td>
            </tr>

            <tr>
              <td className="res-p-l-1">Baden-Baden</td>
              <td>Active</td>
              <td>
                <div className="text-right PH-text-right">
                  <Link
                    to="#"
                    className="d-inline-flex mr-2 align-items-center"
                  >
                    <i class="bx bxs-edit"></i>
                    Edit
                  </Link>
                  <Link to="#" className="d-inline-flex align-items-center">
                    <i class="bx bxs-trash-alt"></i>
                    Remove
                  </Link>
                </div>
              </td>
            </tr>

            <tr>
              <td className="res-p-l-1">Biberach</td>
              <td>Active</td>
              <td>
                <div className="text-right PH-text-right">
                  <Link
                    to="#"
                    className="d-inline-flex mr-2 align-items-center"
                  >
                    <i class="bx bxs-edit"></i>
                    Edit
                  </Link>
                  <Link to="#" className="d-inline-flex align-items-center">
                    <i class="bx bxs-trash-alt"></i>
                    Remove
                  </Link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="content-footer">
          <nav class="nav-content">
            <ul class="pagination">
              <li class="page-item">
                <button class="page-link">
                  <i class="bx bx-chevron-left"></i>
                </button>
              </li>
              <li class="page-item active">
                <button class="page-link">1</button>
              </li>
              <li class="page-item">
                <button class="page-link">2</button>
              </li>
              <li class="page-item">
                <button class="page-link">3</button>
              </li>
              <li class="page-item">
                <button class="page-link">4</button>
              </li>
              <li class="page-item">
                <button class="page-link">
                  <i class="bx bx-chevron-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    ];
  }
}
