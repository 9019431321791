import React from "react";
import { Link, withRouter } from "react-router-dom";
import Translate from "react-translate-component";
import { SidebarContext } from "./SidebarContext";
import { connect } from "react-redux";

class Sidebar extends React.Component {
  static contextType = SidebarContext;
  static subMenu = {
    collapseInvestor: [
      "/investor/products",
      "/investor/requested-products",
      "/investor/accounts",
      "/investor/add-account",
      "/investor/requested-accounts"
    ],
    collapseCreditor: [
      "/creditor/accounts",
      "/creditor/accounts/requested",
      "/creditor/add-account",
      "/creditor/credit-requests"
    ],
    collapseEditor:[
      "/editor/add-editor",
      "/editor/list",
    ],
    collapseSetting: ["/settings/email-template", "/settings/state-county"],
    collapseAdmin: [
      "/admin/add-admin",
      "/admin/list",
      "/settings/service",
      "/settings/industries",
      "/settings/ratings"
    ]
  };

  render() {
    return (
      <div className={`sidebar ${this.context.sidebar}`}>
        <div className={`hamburger ${this.context.sidebar}`}>
          <div
            className="hamburger-bar--close"
            onClick={this.context.toggleSidebar}
          ></div>
        </div>
        <nav className="nav-sidebar">
          <ul className="menu-sidebar" id="menu-sidebar">
            <li className="current-menu has-sub-menu">
              <Link
                to="#"
                id="dropdown-menu"
                data-toggle="collapse"
                data-target="#collapseInvestor"
                aria-expanded="false"
                aria-controls="collapseInvestor"
              >
                <i className="bx bx-dollar-circle"></i>
                <Translate content="sidebar.investor" />
              </Link>
              <ul
                id="collapseInvestor"
                className={`sub-menu collapse ${
                  Sidebar.subMenu.collapseInvestor.includes(
                    this.props.location.pathname
                  )
                    ? "show"
                    : ""
                }`}
                aria-labelledby="headingOne"
                data-parent="#menu-sidebar"
              >
                <li
                  className={
                    this.props.location.pathname === "/investor/products"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link to="/investor/products">
                    <Translate content="label.allproducts" />
                  </Link>
                </li>
                <li
                  className={
                    this.props.location.pathname ===
                    "/investor/requested-products"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link to="/investor/requested-products">
                    <Translate content="sidebar.product_request" />
                  </Link>
                </li>
                <li
                  className={
                    this.props.location.pathname === "/investor/accounts"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link to="/investor/accounts">
                    <Translate content="sidebar.all_accounts" />
                  </Link>
                </li>
                <li
                  className={
                    this.props.location.pathname === "/investor/add-account"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link to="/investor/add-account">
                    <Translate content="sidebar.add_new_account" />
                  </Link>
                </li>
                <li
                  className={
                    this.props.location.pathname ===
                    "/investor/requested-accounts"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link to="/investor/requested-accounts">
                    <Translate content="sidebar.account_request" />
                  </Link>
                </li>
              </ul>
            </li>
            <li className="has-sub-menu">
              <Link
                to="#"
                id="dropdown-menu"
                data-toggle="collapse"
                data-target="#collapseCreditor"
                aria-expanded="true"
                aria-controls="collapseMenu"
              >
                <i className="bx bx-credit-card"></i>
                <Translate content="sidebar.creditor" />
              </Link>
              <ul
                id="collapseCreditor"
                className={`sub-menu collapse ${
                  Sidebar.subMenu.collapseCreditor.includes(
                    this.props.location.pathname
                  )
                    ? "show"
                    : ""
                }`}
                aria-labelledby="headingOne"
                data-parent="#menu-sidebar"
              >
                <li
                  className={
                    this.props.location.pathname === "/creditor/accounts"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link to="/creditor/accounts">
                    <Translate content="sidebar.all_accounts" />
                  </Link>
                </li>
                <li
                  className={
                    this.props.location.pathname ===
                    "/creditor/accounts/requested"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link to="/creditor/accounts/requested">
                    <Translate content="sidebar.account_request" />
                  </Link>
                </li>
                <li
                  className={
                    this.props.location.pathname === "/creditor/add-account"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link to="/creditor/add-account">
                    <Translate content="sidebar.add_new_account" />
                  </Link>
                </li>
                <li
                  className={
                    this.props.location.pathname === "/creditor/credit-requests"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link to="/creditor/credit-requests">
                    <Translate content="sidebar.credit_requests" />
                  </Link>
                </li>
              </ul>
            </li>
            <li className="has-sub-menu">
              <Link
                to="#"
                id="dropdown-menu"
                data-toggle="collapse"
                data-target="#collapseEditor"
                aria-expanded="true"
                aria-controls="collapseMenu"
              >
                <i className="bx bx-user"></i>
                Editor
              </Link>
              <ul
                id="collapseEditor"
                className={`sub-menu collapse ${
                  Sidebar.subMenu.collapseEditor.includes(
                    this.props.location.pathname
                  )
                    ? "show"
                    : ""
                }`}
                aria-labelledby="headingOne"
                data-parent="#menu-sidebar"
              >
                <li
                  className={
                    this.props.location.pathname === "/editor/add-editor"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link to="/editor/add-editor">
                    <Translate content="label.add_new_editor" />
                  </Link>
                </li>
                <li
                  className={
                    this.props.location.pathname === "/editor/list"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link to="/editor/list">
                    <Translate content="label.all_editors" />
                  </Link>
                </li>
              </ul>
            </li>
            { this.props.role === "admin" ? (
              <li className="has-sub-menu">
                <Link
                  to="#"
                  id="dropdown-menu"
                  data-toggle="collapse"
                  data-target="#collapseAdmin"
                  aria-expanded="true"
                  aria-controls="collapseMenu"
                >
                  <i className="bx bx-user"></i>
                  Admin
                </Link>
                <ul
                  id="collapseAdmin"
                  className={`sub-menu collapse ${
                    Sidebar.subMenu.collapseAdmin.includes(
                      this.props.location.pathname
                    )
                      ? "show"
                      : ""
                  }`}
                  aria-labelledby="headingOne"
                  data-parent="#menu-sidebar"
                >
                  <li
                    className={
                      this.props.location.pathname === "/admin/add-admin"
                        ? "current-menu"
                        : null
                    }
                  >
                    <Link to="/admin/add-admin">
                      <Translate content="label.add_new_admin" />
                    </Link>
                  </li>
                  <li
                    className={
                      this.props.location.pathname === "/admin/list"
                        ? "current-menu"
                        : null
                    }
                  >
                    <Link to="/admin/list">
                      <Translate content="label.all_admins" />
                    </Link>
                  </li>
                </ul>
              </li>
            ): null }
          </ul>
        </nav>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.auth.role,
  };
}

export default withRouter(connect(mapStateToProps,{})(Sidebar));
