export default {
  column: {
    name: "Name",
    category: "Category",
    interest: "Interest",
    minimum_credit_amount: "Minimum Amount",
    max_credit_amount: "Maximum Credit Amount",
    available_credit_amount: "Available Amount",
    status: "Status",
    edit: "Edit",
    mindestbetrag: "minimum balance",
    duration: "Duration",
    services: "Services",
    creditorsname: "Creditors Name",
    industry: "Industry",
    investedon: "Invested on",
    investedamount: "Invested amount",
    numberofrequest: "Number of request",
    createdon: "Created on",
    productname: "Product name",
    requeston: "Request on",
    requestedby: "Requested by",
    requestedamount: "Requested amount",
    credittype: "Credit type",
    yourejected: "You rejected this Investment",
    iagree: "I agree to the",
    terms: "terms and conditions",
    addfile: "Add files",
    ordrop: "or drop files here",
    approvedon: "Approved on",
    noattachment: "No Attachments available",
    appreject: "You have rejected this application",
    appaccept: "You have accepted this application",
    norating: "No ratings available",
    minduration: "Minimum Duration",
    maxduration: "Maximum Duration",
    min_sales_creditor: "Minimum Sales Creditor",
    city: "City",
    account_status: "Account Status",
    max_amount: "Max Amount",
    min_amount: "Min Amount",
    email: "Email",
    nodocument: "No Documents Available",
    domain: "Domain",
  },
  sidebar: {
    product: "Product",
    new_product: "New Product",
    invested_products: "Invested Products",
    products: "Products",
    credit_requested_products: "Credit Request",
    product_request: "Product Requests",
    all_accounts: "All Accounts",
    add_new_account: "Add New Account",
    account_request: "Account Request",
    creditor: "Creditor",
    credit_requests: "Credit Requests",
    investor: "Investor",
    ratings: "Ratings",
    stateCounty: "State/County",
    emailTemplate: "Email Template"
  },
  label: {
    Produktdetail: "Product detail",
    processing: "Processing",
    AngebotErstellt: "Offer sent",
    theperfect: "The Perfect Investment",
    Sicherheiten: "Collateral",
    Ratingagentur: "Rating agency",
    Kreditrating: "credit rating",
    atcredittech:
      "At Credittech you can easily find creditor and make an investment",
    whycredittech: "Why Credit Tech?",
    atfirst:
      "At first I Invested little and by time pass I Invested to the sector I like and Credit Tech takes me right there",
    whatothers: "What others say?",
    signupto: "Sign Up to Credit Tech as an Investor",
    enteryourdetails: "Enter your details below",
    firstname: "First Name",
    lastname: "Last Name",
    companyname: "Company Name",
    emailaddress: "Email Address",
    password: "Password",
    confirmpassword: "Confirm Password",
    phonenumber: "Phone Number",
    alreadyhaveanaccount: "Already have an account ? ",
    login: "Login",
    lorem:
      "Lorem ipsum dolor sit amet, tempor labore sadipscing over the years, sed diam tempor sed ut labore et dolore magna per month.",
    ifyoudont: "If you dont have account you can sign up ",
    ypudont: "You dont have any new notifications",
    enteryouremail: "Enter your email address and password",
    here: "here",
    sendit: "Send it again",
    didnt: "Didn’t recieve an email?",
    youraccounthas:
      "Your account has been successfully created. Please check your email for the confirmation",
    weve: "We’ve just send you an Email",
    activation: "Activation",
    adminapproval: "Admin Approval",
    confirmemail: "Confirm Email",
    theconfirm: "The confirmation link has expired.",
    wecant: "We cant verify your email address.",
    ouradminneed:
      "Our admin needs to confirm the account first. We will send you an Email when its done",
    justonemore: "Just one more step, Admin Approval",
    youcanask: "You can ask for a new validation link here.",
    youraccounthasbeen:
      "Your account has been activated. From now on you can use our service freely",
    wow: "Wow, Welcome to Pihub",
    attachments: "Attachments",
    timeduration: "Time duration(months)",
    investor: "Investor",
    maxcredit: "Maximum Credit Amount",
    industries: "Industries",
    state: "State",
    service: "Service",
    producttitle: "Product Title",
    requested: "Requested",
    approved: "Approved",
    rejected: "Rejected",
    ZuBearbeiten: "To edit",
    invested: "Invested",
    allproducts: "All Products",
    fileupload: "File Upload",
    no: "No",
    yes: "Yes",
    rating: "Rating for Credit",
    country: "County",
    editproducts: "Edit Products",
    mincredit: "Minimum credit Value",
    notifications: "Notifications",
    pagenot: ":( 404 - Page Not Found.",
    thepage:
      "The page you are looking for might have been removed had its name changed or is temporarily unavailable.",
    back: "Back to Homepage ",
    logout: "Logout",
    accept: "Accept/decline",
    requestedamount: "Requested amount",
    time: "Time duration",
    deadline: "Deadline",
    applicationcode: "Application code",
    creditrequests: "Credit requests",
    Kreditorname: "Lender",
    Corporateloan: "Corporate loan ",
    purchase: "Purchase financing / Finetrading",
    stocktrading: "Stocktrading",
    acquisition: "Acquisition / Takeover financing",
    project: "Project financing",
    mezzanine: "Mezzanine financing",
    youdonot: "You do not have any products yet! ",
    profile: "Profile",
    editprofile: "Edit Profile",
    minimumsales: "Minimum Sales Creditor",
    resetpassword: "Reset Password",
    months: "Months",
    open: "Open",
    postponed: "Postponed",
    deleted: "Deleted",
    deletedmsg: "This product has been deleted",
    postponedmsg: "This product has been postponed. You can change the status",
    new: "New",
    accepted: "Accepted",
    requested_products: "Requested Products",
    add_new_account: "Add New Account",
    type_of_user: "Type of User",
    requested_account: "Account Request",
    creditor_all_account: "Creditor: All Accounts",
    creditor_requested_accounts: "Creditor:Requested Accounts",
    offer_accepted: "Offer Accepted",
    offer_received: "Offer Received",
    offer_rejected: "Offer Rejected",
    questions_for_creditor: "Questions for Creditor",
    verified: "Verified",
    unverified: "Unverified",
    contact_person: "Contact Person",
    requested_for: "Requested For",
    credit_request_for: "Credit Request For",
    requested_by: "Requested By",
    amount: "Amount",
    description: "Description",
    investment_available_of: "Investment Available of",
    role: "Role",
    add_new_admin: "Add New Admin",
    admin: "Administrators",
    all_admins: "All Admins",
    salesAmount: "Sales Amount",
    deadlineForPayment: "Deadline for payment",
    ratingForCredit: "Rating for credit",
    productDetailOf: "Product detail of",
    all: "All",
    questionsForCreditor: "Question for creditor",
    offerRejected: "Offer rejected",
    offerAccepted: "Offer accepted",
    matchedProductForRequest: "Matched product for request",
    name: "Name",
    industry: "Industry",
    creditType: "Credit Type",
    county: "County",
    minCreditAmount: "Min credit amount",
    maxCreditAmount: "Max credit amount",
    justASec: "Just a sec",
    purpose: "Purpose",
    nda: "NDA",
    addInvestorAccount: "Add Investor Account",
    addCreditorAccount: "Add Creditor Account",
    companyName: "Company Name",
    headquarter: "Headquarter",
    streetAddress: "Street and housenumber",
    postalCode: "Postal Code",
    contactPerson: "Contact Person",
    socialMedia: "Social Media",
    fileUpload: "File Upload",
    update: "Update",
    editProfile: "Edit Profile",
    youdont: "You dont have any new notifications",
    changePassword: "Change Password?",
    enterAnEmailAssociated: "Enter an email associated with your account",
    passwordChangedSuccessful: "Successfully Password Changed",
    youCanUseYourNewPassword:
      "You can use your new password to log in to your creditech account.",
    withYourNewPassword: "with your new password",
    weCantseem: "Sorry we can't seem to find the page you're looking for",
    takeMeHome: "Take me home",
    documents: "Documents",
    setCreditRequest: "Set maximum credit request",
    add_new_editor: "Add New Editor",
    all_editors: "All Editors",
    editors: "Editors",
    registered_domain: "Domain",
    matching_option: "Matching Option",
    loginPortal: "Portal",
    resetYourPassword: "Reset your password"
  },
  button: {
    matching_auto: "Matching Auto",
    matching_manual: "Matching Manual",
    matching_deligate: "Deligate",
    Produktbearbeiten: "Edit Product",
    signup: "Sign up Now",
    search: "Search",
    addnewproduct: "Add new products",
    submit: "Submit",
    edit: "Edit Product",
    download: "Download",
    delete: "Delete",
    postpone: "Postpone",
    undopostpone: "Undo postpone",
    approve: "Approve",
    disapprove: "Disapprove",
    accept: "Accept",
    reject: "Reject",
    logout: "Log Out",
    show_match: "Show Matches",
    moreDetail: "More Detail",
    send: "Send",
    close: "Close",
    downloadAll: "Download All",
    resendConfirmation: "Resend Confirmation"
  },
  placeholder: {
    select: "Select",
    search: "Search",
    verified: "Verified",
    unverified: "Unverified",
    NewPassword: "New Password",
    Retypepassword: "Retype password",
    changeProfilePic: "Change Profile Picture",
    enterYourWebsite: "Enter your website URL",
    companyName: "Company Name inc.",
    headquarterLocation: "Headquarter Location",
    contactName: "Contact Name",
    contactName2: "Contact Name-2",
    contactName3: "Contact Name-3",
    contactEmail: "Contact Email",
    contactEmail2: "Contact Email-2",
    contactEmail3: "Contact Email-3",
    contactPhoneNo: "Contact phone number",
    contactPhoneNo2: "Contact phone number-2",
    contactPhoneNo3: "Contact phone number-3",
    facebookURL: "Facebook URL",
    twitterURL: "Twitter URL",
    LinkedinURL: "Linkedin URL",
    xingURL: "Xing URL"
  },
  unverified: {
    msg:
      "Your account is not yet verified yet. Please contact the administrator"
  },
  message: {
    no_product: "No Product Found",
    no_user: "No User Found",
    no_application: "No Application Found",
    not_available: "Not Available",
    tax: "taxes may apply as per country",
    done:"Done!"
  },
  validation: {
    required: "* Required",
    email: "Invalid email address",
    phoneNumber: "Invalid phone number, must be 10 digits",
    number: "* Must be a number",
    same: "Password Mismatch",
    password:
      "Password must contain atleast one Capital letter, one small letter, a special character (@, #, $) , one numeric value and must be 8 digit long",
    newEmail: "Invalid Email!",
    fillAllRequiredFields: "Please fill all the required fields.",
    url: "Url format mismatch, eg: puhub-pi.com"
  },
  modal: {
    approveUser: "Approve User",
    userConfirm:
      "Street Address, Headquarter and Zip/Postal Code are empty. Are you sure you want to approve?",
    approveIt: "Yes! Approve it anyway"
  },
  alert: {
    user_deleted: "User Deleted",
    failed_to_delete_creditor: "Unable to delete the user, user has applications"
  }
};
