import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Translate from "react-translate-component";
import { updateApplicationLimit } from "../../actions/users";
import { updateApplicationLimitEditor } from "../../actions/Editor/users";
import FlashMessage from "../general/FlashMessage";
import  * as flashMessage from "../../actions/flashMessage";

class ApplicationLmit extends Component{
    handleSubmit = (e) => {
        e.preventDefault();
        this.updateApplicationLimit(this.props.creditor_id, this.props.application_limit).then(response => {
            if(response){
                this.props.showFlashMessage("message.done");
                setTimeout(() => {
                    this.props.hideFlashMessage();
                }, 5000);
            }
        });
    }
    updateApplicationLimit = (id, application_limit, callback) => {
      if(this.props.role === "admin") {
        return this.props.updateApplicationLimit(id, application_limit, callback);
      } else {
        return this.props.updateApplicationLimitEditor(id, application_limit, callback);
      }
    }
    showError = errors => {
        if (errors) {
            let ind = 0;
            for(let error in errors){
            ind++;
            return (
                <span key={ind}>
                <font color="red">
                    {errors[error][0]}
                    <br />
                </font>
                </span>
            );
            } 
        }
    };

    render(){
        return(
            <Fragment>
            <div className="d-flex mt-4">
              
                    <label>
                    <Translate content="label.setCreditRequest" />:
                    <input type="number"  value={this.props.application_limit} onChange={(e) => {
                      this.props.onChange(e.target.value)
                    }} />
                    </label>
                  <button
                    className="btn btn-accept ml-4"
                    onClick={(e)=>{ this.handleSubmit(e); }}
                  >
                    <Translate content="button.submit" />
                  </button>
            
            </div>
            {this.props.errors ?(
              <div>
                {this.showError(this.props.errors.errors)}
              </div>
            )
              : null
            }
            <FlashMessage data={this.props.flashMessage} />
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
      errors: state.error,
      flashMessage: state.flashMessage,
      role: state.auth.role,
    };
}

export default connect(mapStateToProps, {
    updateApplicationLimit,
    updateApplicationLimitEditor,
    ...flashMessage
  })(ApplicationLmit);