import React, { Component, Fragment } from "react";
import Translate from "react-translate-component";
import { Field, reduxForm } from "redux-form";
import { inputField } from "../formFields";
import {
  getTokenForEmail,
  changePasswordWithToken
} from "../../actions/password";
import { compose } from "redux";
import { connect } from "react-redux";
import * as validation from "../../utils/validate";
import { Link } from "react-router-dom";

class ChangePassword extends Component {
  state = { submit: false, token: null };
  onSubmit = formProps => {
    if (!this.state.submit) {
      this.props.getTokenForEmail(formProps, token => {
        this.setState({ submit: true, token: token });
      });
    } else {
      formProps.token = this.state.token;
      this.props.changePasswordWithToken(formProps, () => {
        this.props.history.push("/password-change-success");
      });
    }
  };

  displayErrors = errors => {
    if(errors) {
      return errors.map((err, index) => {
        return (
          <li className="d-flex mb-1" key={index}>
            <i className="bx bx-x-circle icon-danger">
              <font color="red" className="pl-2 ">
                {err}
              </font>{" "}
            </i>
          </li>
        );
      });
    } else {
      return "";
    }
    
  };

  render() {
    const { handleSubmit } = this.props;
    if (!this.state.submit) {
      return (
        <Fragment>
          <Link to="/">
            <img
              className="company-logo company-logo-email"
              src="/assets/img/logo.png"
              alt="company logo"
            />
          </Link>
          <div className="container-full-height text-centerd d-flex">
            <div className="content m-auto">
              <div className="email-content">
                <div className="w-75 m-auto text-center">
                  <img src="/assets/img/icons/activated.png" alt="Mail icon" />
                  <Translate content="label.changePassword" component="h3" />
                  <Translate
                    content="label.enterAnEmailAssociated"
                    component="p"
                  />
                </div>
                <div className="w-75 m-auto">
                  <form
                    className="form-signin"
                    onSubmit={handleSubmit(this.onSubmit)}
                  >
                    <div className="form-group text-left w-75">
                      <Field
                        type="email"
                        name="email"
                        component={inputField}
                        className="form-control"
                        placeholder="column.email"
                      />
                    </div>
                    {console.log(this.props)}

                    {this.props.errMsg
                      ? this.displayErrors(this.props.errMsg)
                      : null}
                    <Translate
                      content="button.submit"
                      component="button"
                      className="btn btn-primary btn-form"
                      type="submit"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>        
          <Link to="/">
            <img
              className="company-logo company-logo-email"
              src="/assets/img/logo.png"
              alt="company logo"
            />
          </Link>
          <div className="container-full-height text-centerd d-flex">
            <div className="content m-auto">
              <div className="email-content">
                <div className="w-75 m-auto text-center">
                  <img src="/assets/img/icons/activated.png" alt="Mail icon" />
                  <Translate content="label.resetYourPassword" component="h3" />
                </div>
                <div className="w-75 m-auto">
                  <form
                    className="form-signin"
                    onSubmit={handleSubmit(this.onSubmit)}
                  >
                    <div className="form-group text-left w-75">
                      <Field
                        type="password"
                        name="password"
                        component={inputField}
                        className="form-control"
                        placeholder="placeholder.NewPassword"
                      />
                    </div>{" "}
                    <div className="form-group text-left w-75">
                      <Field
                        type="password"
                        name="password_confirmation"
                        component={inputField}
                        className="form-control"
                        placeholder="placeholder.Retypepassword"
                      />
                    </div>
                    {this.props.errMsg
                      ? this.displayErrors(this.props.errMsg)
                      : null}
                    <Translate
                      content="button.submit"
                      component="button"
                      className="btn btn-primary btn-form"
                      type="submit"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

function validate(values) {
  const errors = {};
  errors.email = validation.newEmail(values.email);
  errors.password = validation.required(values.password);
  errors.password = validation.password(values.password);
  if (values.password !== values.password_confirm) {
    errors.password_confirm = validation.same(values.password_confirm);
  }
  return errors;
}

function mapStateToProps(state) {
  return { errMsg: state.error };
}

export default compose(
  connect(mapStateToProps, { changePasswordWithToken, getTokenForEmail }),
  reduxForm({
    form: "changePassword",
    validate
  })
)(ChangePassword);
