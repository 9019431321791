import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Translate from "react-translate-component";
import { allProducts } from "../../../actions/product";
import { allProductsEditor } from "../../../actions/Editor/product";
import Loading from "../../general/Loading";
import ProductList from "./ProductList";
class AllProducts extends Component {
  state = {
    products: [],
    product_title: "",
    status: null,
    search_product_title: ""
  };
  
  allProducts = (
    page,
    requested,
    status,
    product_title,
    investor_id
  ) => {
    if(this.props.role === "admin") {
      return this.props.allProducts(page,requested,status,product_title,investor_id);
    } else {
      return this.props.allProductsEditor(page,requested,status,product_title,investor_id);
    }
  }
  render() {
    return (
      <Fragment>
        <div className="content-head">
          <div className="content-head-left">
            <h1 className="content-head__title">
              <Translate content="label.allproducts" />
            </h1>
          </div>
          <div className="content-head-right">
            <Loading />
            <div className="row">
              <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div className="form-group">
                  <select
                    className="form-control"
                    name="select"
                    onChange={e =>
                      this.setState({
                        status: e.target.value
                      })
                    }
                  >
                    <Translate
                      component="option"
                      attributes={{ label: "label.all" }}
                      value=""
                    />
                    <Translate
                      component="option"
                      attributes={{ label: "label.open" }}
                      value="open"
                    />
                    <Translate
                      component="option"
                      attributes={{ label: "label.accepted" }}
                      value="accepted"
                    />
                    <Translate
                      component="option"
                      attributes={{ label: "label.rejected" }}
                      value="rejected"
                    />
                    <Translate
                      component="option"
                      attributes={{ label: "label.new" }}
                      value="new"
                    />
                  </select>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 pl-0">
                <div className="form-group">
                  <Translate
                    component="input"
                    className="form-control"
                    type="text"
                    name="search-text"
                    attributes={{ placeholder: "placeholder.search" }}
                    value={this.state.search_product_title}
                    onChange={e =>
                      this.setState({ search_product_title: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-0">
                <Translate
                  content="button.search"
                  component="button"
                  className="btn btn-primary"
                  onClick={e => {
                    e.preventDefault();
                    (async () => {
                      await this.setState({
                        product_title: this.state.search_product_title
                      });
                      this.allProducts(
                        1,
                        false,
                        this.state.status,
                        this.state.product_title
                      );
                    })();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <ProductList status={this.state.status} />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { 
    products: state.products,
    pagination: state.pagination,
    role: state.auth.role,
  };
}
export default connect(mapStateToProps, { allProducts, allProductsEditor })(AllProducts);
