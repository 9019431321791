import React, { Component } from "react";
import { connect } from "react-redux";
import { allProducts } from "../../actions/product";
import { allProductsEditor } from "../../actions/Editor/product";
import { getAllUsers } from "../../actions/users";
import { getAllUsersEditor } from "../../actions/Editor/users";
class Pagination extends Component {
  state = { currentPage: 1 };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentPage !== prevState.currentPage) {
      this.props.url(this.state.currentPage);
    }
  }
  renderPage = () => {
    const pageLinks = [];

    for (let i = 1; i <= this.props.totalPage; i++) {
      pageLinks.push(
        <li
          key={i}
          className={
            this.state.currentPage === i ? "page-item active" : "page-item"
          }
        >
          <button
            className="page-link"
            onClick={() => {
              this.setState({ currentPage: i });
            }}
          >
            {i}
          </button>
        </li>
      );
    }

    return pageLinks;
  };
  render() {
    const { totalPage } = this.props;
    const { currentPage } = this.state;
    return (
      <div className="content-footer">
        <nav className="nav-content">
          <ul className="pagination">
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => {
                  if (currentPage > 1)
                    this.setState({ currentPage: currentPage - 1 });
                }}
              >
                <i className="bx bx-chevron-left" />
              </button>
            </li>
            {this.renderPage()}
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => {
                  if (currentPage < totalPage)
                    this.setState({ currentPage: currentPage + 1 });
                }}
              >
                <i className="bx bx-chevron-right" />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
export default connect(null, { allProducts, allProductsEditor, getAllUsers, getAllUsersEditor })(Pagination);
