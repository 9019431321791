import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Translate from "react-translate-component";
import { ToEuro } from "../../../utils/misc";
import { productDetail, changeProductStatus } from "../../../actions/product";
import { productDetailEditor, changeProductStatusEditor } from "../../../actions/Editor/product";
import { clearError } from "../../../actions/clearError";
import { downloadFile } from "../../../actions/downloadFile";
import Loading from "../../general/Loading";
import FileList from "../../general/FileList";
class ProductDetail extends Component {
  state = { detail: null };
  componentDidMount() {
    if (!this.props.location.state) {
      return this.props.history.push("/investor/products");
    }
    this.props.clearError();
    const { id } = this.props.location.state;
    if(this.props.role === "admin") {
      this.props.productDetail(id);
    } else {
      this.props.productDetailEditor(id);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.detail !== prevProps.detail) {
      this.setState({ detail: this.props.detail.detail });
    }
  }

  changeProductStatus = (id, status, callback) => {
    if(this.props.role === "admin") {
      return this.props.changeProductStatus(id, status, callback);
    } else {
      return this.props.changeProductStatusEditor(id, status, callback);
    }
  }

  showAttachments = documents => {
    if (documents.length === 0) {
      return <Translate content="column.noattachment" />;
    }
    return (
      <FileList documents={documents} />
    )  
  };

  downloadAll = documents => {
    const filePathList = documents.map(document => document.path);
    this.props.downloadAllToken(filePathList);
  };

  showAddress = arrayOfObjects => {
    if (arrayOfObjects.length === 0) {
      return <p className="product__info">Not available</p>;
    } else {
      return arrayOfObjects.map((object, index) => {
        return (
          <div key={index}>
            <span>{object.name}</span>
          </div>
        );
      });
    }
  };

  listIndustries = industries => {
    return industries.map((industry, index) => {
      return (
        <div key={index} className="mb-1">
          {industry.name ? industry.name[this.props.language] : null}
        </div>
      );
    });
  };

  listRating = ratings => {
    if (ratings.length === 0) {
      return (
        <span>
          **
          <Translate content="column.norating" />
        </span>
      );
    } else {
      return ratings.map((rating, index) => {
        return (
          <div key={index} className="col-4 col-sm-4 col-md-4 col-lg-4">
            <div className="d-flex mb-2">
              <span className="mr-3">{rating.name[this.props.language]}</span>
              <span className="font-weight-bold">{rating.value}</span>
            </div>
          </div>
        );
      });
    }
  };

  render() {
    if (this.state.detail) {
      const {
        id,
        investor,
        investor_id,
        states,
        counties,
        collatoral,
        industries,
        max_credit_amount,
        min_credit_amount,
        min_sales_creditor,
        min_time_duration,
        max_time_duration,
        product_title,
        ratings,
        service,
        registered_domain,
        status,
        documents
      } = this.state.detail;
      return (
        <Fragment>
          <div className="content-head">
            <div className="content-head-left">
              <h1 className="content-head__title">
                <Translate content="label.productDetailOf" className="mr-1" />
                {product_title}
              </h1>
            </div>
          </div>
          <div className="content-body credit-request">
            {/* <div className="d-flex align-items-center justify-content-between">
              <div className="item"><span>
                <Translate content='label.requested_for' />
              </span>
                <h2>IT Investment</h2>
              </div>
              {/* <div className="item ml-4"><a className="btn btn-primary" href="add-new-products.html">View Corporate</a></div> */}
            {/* </div> */}
            <div className="product-details row">
              <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                <div className="row">
                  <div className="col-4 product-info">
                    {/* <h6>Product Title</h6> */}
                    <Translate content="label.producttitle" component="h6" />
                    {product_title}
                  </div>
                  <div className="col-4 product-info">
                    <Translate content="label.service" component="h6" />
                    {service.name ? service.name[this.props.language] : null}
                  </div>
                  <div className="col-4 product-info">
                    <Translate content="label.state" component="h6" />
                    <div className="product-info-detail">
                      {states ? this.showAddress(states) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 product-info">
                    {/* <Translate content='label.state' component="h6" /> */}
                    <Translate content="label.country" component="h6" />
                    <div className="product-info-detail">
                      {states ? this.showAddress(counties) : null}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 product-info">
                    <Translate content="label.industries" component="h6" />
                    <div className="product-info-detail">
                      {industries ? this.listIndustries(industries) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                    <Translate content="label.Sicherheiten" component="h6" />
                    {collatoral ? (
                      <Translate content="label.yes" />
                    ) : (
                      <Translate content="label.no" />
                    )}
                  </div>
                </div>
                <div className="row flex-row mt-3">
                  <h6 className="w-100 pl-3">
                    <Translate content="label.ratingForCredit" />
                  </h6>

                  <div className=""></div>
                  {ratings ? this.listRating(ratings) : null}
                </div>
                { registered_domain &&
                  <div className="row flex-row mt-3">
                    <h6 className="w-100 pl-3">
                      <Translate content="column.domain" />
                    </h6>

                    <div className=""></div>
                    {registered_domain}
                  </div>
                }
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 product-details-right">
                <div className="row">
                  <div className="col-12 investor">
                    <Translate content="sidebar.investor" component="h6" />
                    <Link
                      to={{
                        pathname: "/user/detail",
                        state: { id: investor_id, from: "investor" }
                      }}
                    >
                      {investor}
                    </Link>{" "}
                  </div>
                  <div className="col-12 amount mt-3">
                    {/* <h6>Max Credit Amount</h6> */}
                    <Translate content="label.maxcredit" component="h6" />

                    <ToEuro amount={max_credit_amount} />
                  </div>
                  <div className="col-12 amount mt-3">
                    {/* <h6>Max Credit Amount</h6> */}
                    {/* <h6>Mindestkreditbetrag</h6> */}
                    <Translate
                      content="column.minimum_credit_amount"
                      component="h6"
                    />
                    <ToEuro amount={min_credit_amount} />
                  </div>
                  <div className="col-12 mt-3">
                    <Translate content="label.minimumsales" component="h6" />
                    <ToEuro amount={min_sales_creditor} />
                  </div>
                  <div className="col-12 date mt-3">
                    {/* <h6>Time Duration</h6> */}
                    <Translate content="column.minduration" component="h6" />
                    {min_time_duration} <Translate content="label.months" />
                  </div>
                  <div className="col-12 date mt-3">
                    {/* <h6>Time Duration</h6> */}
                    <Translate content="column.maxduration" component="h6" />
                    {max_time_duration} <Translate content="label.months" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="attachments mt-4">
            <h4>
              <Translate content="label.attachments" />
            </h4>
            {documents ? this.showAttachments(documents) : null}
          </div>
          <br />
          <br />
          {this.props.errMsg ? (
            <font color="red">{this.props.errMsg}</font>
          ) : null}
          {status === "approved" ? null : status === "rejected" ? null : registered_domain !== this.props.registered_domain ? null : (
            <div className="d-flex">
              <div className="item">
                <button
                  className="btn btn-success mt-5 mr-3"
                  onClick={() =>
                    this.changeProductStatus(id, "accepted", () => {
                      this.props.history.push("/investor/products");
                    })
                  }
                >
                  <Translate content="button.accept" />
                </button>
              </div>
              {/* <div className="item"><a className="btn btn-info mt-5 mr-3" href="add-new-products.html">More Detail    </a></div> */}
              <div className="item">
                <button
                  className="btn btn-danger mt-5"
                  onClick={() =>
                    this.changeProductStatus(id, "rejected", () => {
                      this.props.history.push("/investor/products");
                    })
                  }
                >
                  <Translate content="button.reject" />
                </button>
              </div>
            </div>
          )}
          {/* </div> */}
          {/* Delete table */}
          {/* <ApplicationList id={this.props.location.state.id} /> */}
        </Fragment>
      );
    } else {
      return <Loading />;
    }
  }
}
function mapStateToProps(state) {
  return { 
    detail: state.productDetail,
    errMsg: state.error, 
    language:state.language, 
    registered_domain: state.auth.registered_domain,
    role: state.auth.role,
  };
}
export default connect(mapStateToProps, {
  clearError,
  changeProductStatus,
  changeProductStatusEditor,
  productDetail,
  productDetailEditor,
  downloadFile,
})(ProductDetail);
