import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Translate from "react-translate-component";
import Moment from "react-moment";
import {getAllUsers, removeUser} from "../../actions/users";
import {getAllUsersEditor} from "../../actions/Editor/users";
import Pagination from "../general/Pagination";
import Loading from "../general/Loading";
import {hideFlashMessage, showFlashMessage} from "../../actions/flashMessage";

class ListAccount extends Component {
    state = {userList: [], status: "", search_user: "", search_user_name: ""};

    componentDidMount() {
        this.getAllUsers(1, "creditor");
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.users !== prevProps.users) {
            this.setState({
                userList: this.props.users.list
            });
        }
        if (this.state.status !== prevState.status) {
            this.getAllUsers(1, "creditor", "", this.state.status);
        }
    }

    getAllUsers = (
        page,
        role,
        requested,
        status,
        search_user
    ) => {
        if (this.props.role === "admin") {
            return this.props.getAllUsers(page, role, requested, status, search_user);
        } else {
            return this.props.getAllUsersEditor(page, role, requested, status, search_user);
        }
    }

    renderList = users => {
        if (users.length === 0) {
            return (
                <tr>
                    <td>
                        <Translate content="message.no_user"/>
                    </td>
                </tr>
            );
        } else {
            return users.map((user, index) => {
                const {
                    email,
                    id,
                    name,
                    phone_number,
                    status,
                    street_address,
                    registered_domain,
                    created_on
                } = user;
                return (
                    <tr key={index}>
                        <td className="res-p-l-1">
                            <Link
                                to={{
                                    pathname: "/user/detail",
                                    state: {id: id, from: "creditor"}
                                }}
                            >
                                {name}
                            </Link>
                        </td>
                        <td>
                            {street_address || <Translate content="message.not_available"/>}
                        </td>
                        <td className="PH-text-right">{email}</td>
                        <td className="PH-text-right">{phone_number}</td>
                        <td className="PH-text-right">
                            <Moment format="DD.MM.YYYY">{created_on}</Moment>
                        </td>
                        <td className="PH-text-right">{registered_domain}</td>
                        {status === "accepted" ? (
                            <td className="align-left">
                                {" "}
                                <Translate
                                    content="label.accepted"
                                    className="badge badge-success"
                                />
                            </td>
                        ) : null}
                        {status === "new" ? (
                            <td className="align-left">
                                {" "}
                                <Translate
                                    content="label.new"
                                    className="badge badge-primary"
                                />
                            </td>
                        ) : null}
                        {status === "rejected" ? (
                            <td className="align-left">
                                <Translate
                                    content="label.rejected"
                                    className="badge badge-danger"
                                />
                            </td>
                        ) : null}
                        {status === "open" ? (
                            <td className="align-left">
                                <Translate content="label.open" className="badge badge-open"/>
                            </td>
                        ) : null}
                        <td className="text-right PH-text-right">
                            <Link to="#" className="remove-icon" onClick={() => {
                                this.props.removeUser(id, () => {
                                    this.props.showFlashMessage("alert.user_deleted");
                                    this.getAllUsers(
                                        this.props.pagination.currentPage,
                                        "creditor",
                                        "",
                                        this.state.status,
                                        this.state.search_user
                                    );
                                    setTimeout(() => {
                                        this.props.hideFlashMessage();
                                    }, 5000);
                                }, (error) => {
                                    this.props.showFlashMessage("alert.failed_to_delete_creditor")
                                    setTimeout(() => {
                                        this.props.hideFlashMessage();
                                    }, 5000);
                                })
                            }}>
                                <i className="bx bxs-trash-alt"></i> Remove
                            </Link>
                        </td>
                    </tr>
                );
            });
        }
    };

    render() {
        const {totalPage} = this.props.pagination;
        return (
            <Fragment>
                <div className="content-head">
                    <div className="content-head-left">
                        <h1 className="content-head__title">
                            <Translate content="label.creditor_all_account"/>
                        </h1>
                    </div>
                    <div className="content-head-right">
                        <form className="form-filter" action="">
                            <div className="row">
                                <div className="col-6 col-md-3">
                                    <div className="form-group">
                                        <select
                                            className="form-control"
                                            name="select"
                                            onChange={e => {
                                                this.setState({status: e.target.value});
                                            }}
                                        >
                                            <Translate
                                                component="option"
                                                attributes={{label: "label.all"}}
                                                value=""
                                            />
                                            <Translate
                                                component="option"
                                                attributes={{label: "label.open"}}
                                                value="open"
                                            />
                                            <Translate
                                                component="option"
                                                attributes={{label: "label.accepted"}}
                                                value="accepted"
                                            />
                                            <Translate
                                                component="option"
                                                attributes={{label: "label.rejected"}}
                                                value="rejected"
                                            />
                                            <Translate
                                                component="option"
                                                attributes={{label: "label.new"}}
                                                value="new"
                                            />
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 pl-0">
                                    <div className="form-group">
                                        <Translate
                                            component="input"
                                            className="form-control"
                                            type="text"
                                            name="search-text"
                                            attributes={{placeholder: "placeholder.search"}}
                                            onChange={e =>
                                                this.setState({search_user_name: e.target.value})
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-5 col-md-3 btn-search">
                                    <Translate
                                        content="button.search"
                                        component="button"
                                        className="btn btn-primary col-12"
                                        onClick={e => {
                                            e.preventDefault();
                                            (async () => {
                                                await this.setState({
                                                    search_user: this.state.search_user_name
                                                });
                                                this.getAllUsers(
                                                    this.props.pagination.currentPage,
                                                    "creditor",
                                                    "",
                                                    this.state.status,
                                                    this.state.search_user
                                                );
                                            })();
                                        }}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="content-body">
                    <Loading/>
                    <table
                        className="table tablesaw-stack"
                        data-tablesaw-mode="stack"
                        data-tablesaw-minimap="data-tablesaw-minimap"
                    >
                        <thead>
                        <tr>
                            <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                                Name
                            </th>
                            <th
                                data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                                data-tablesaw-priority="persist"
                                scope="col"
                            >
                                <Translate content="column.city"/>
                            </th>
                            <th
                                className="PH-text-right"
                                data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                                scope="col"
                            >
                                Email
                            </th>
                            <th
                                className="PH-text-right"
                                data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                                scope="col"
                            >
                                <Translate content="label.phonenumber"/>
                            </th>
                            <th
                                className="PH-text-right"
                                data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                                scope="col"
                            >
                                <Translate content="column.createdon"/>
                            </th>
                            <th
                                className="PH-text-right"
                                data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                                scope="col"
                            >
                                <Translate content="column.domain"/>
                            </th>
                            <th
                                className="text-right"
                                data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                                scope="col"
                            >
                                <Translate content="column.account_status"/>
                            </th>
                            <th
                                className="text-right"
                                data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                                scope="col"
                            >
                                Action
                            </th>
                        </tr>
                        </thead>
                        <tbody>{this.renderList(this.state.userList)}</tbody>
                    </table>
                    <Pagination
                        totalPage={totalPage}
                        url={page =>
                            this.getAllUsers(
                                page,
                                "creditor",
                                "",
                                this.state.status,
                                this.state.search_user
                            )
                        }
                    />
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        users: state.users,
        pagination: state.pagination,
        role: state.auth.role,
    };
}

export default connect(mapStateToProps, {
    getAllUsers,
    getAllUsersEditor,
    removeUser,
    showFlashMessage,
    hideFlashMessage
})(ListAccount);
