import client from "./index";
import { routes } from "./../_api/routes";
import {
  ERROR,
  ALL_PRODUCTS,
  PRODUCT_DETAIL,
  PAGINATION,
  LOADING,
  LOADING_DONE
} from "./types";
export const allProducts = (
  page,
  requested,
  status,
  product_title,
  investor_id
) => async dispatch => {
  try {
    dispatch({
      type: LOADING,
      payload: {
        URL: `${routes.allProducts}`,
        method: "get",
        data: { page, requested, status }
      }
    });
    let response = await client.get(
      `${routes.allProducts}?page=${page}
      &requested=${requested}
      ${status ? "&status=" + status : ""}
      ${product_title ? "&product_title=" + product_title : ""}
      ${investor_id ? "&investor_id=" + investor_id : ""}`
    );
    dispatch({
      type: ALL_PRODUCTS,
      payload: response.data.data
    });

    dispatch({
      type: PAGINATION,
      payload: response.data.meta
    });
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.allProducts}`,
        method: "get",
        data: { page, requested, status }
      }
    });
  } catch (e) {
    // dispatch({
    //   type: ERROR,
    //   payload: `${e.response.data.error}.`
    // });
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.allProducts}`,
        method: "get",
        data: { page, requested, status }
      }
    });
  }
};

export const productDetail = id => async dispatch => {
  try {
    dispatch({
      type: LOADING,
      payload: { URL: `${routes.allProducts}`, method: "get", data: { id } }
    });
    const response = await client.get(`${routes.allProducts}/${id}`);
    dispatch({
      type: PRODUCT_DETAIL,
      payload: response.data.data
    });
    dispatch({
      type: LOADING_DONE,
      payload: { URL: `${routes.allProducts}`, method: "get", data: { id } }
    });
  } catch (e) {
    dispatch({
      type: LOADING_DONE,
      payload: { URL: `${routes.allProducts}`, method: "get", data: { id } }
    });
  }
};

export const changeProductStatus = (id, status, callback) => async dispatch => {
  try {
    const response = await client.put(`${routes.allProducts}/${id}/status`, {
      status
    });
    if (response) {
      callback();
    }
  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e.response.data.message
    });
  }
};

export const getProductApplication = id => async dispatch => {
  try {
    await client.get(`${routes.getProductApplicationList}/${id}/applications`);
  } catch (e) {
    console.log(e);
  }
};
