import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Translate from "react-translate-component";
import { getAllUsers } from "../../actions/users";
import { getAllUsersEditor } from "../../actions/Editor/users";
import Moment from "react-moment";
import Pagination from "../general/Pagination";
import Loading from "../general/Loading";
class AccountRequest extends Component {
  state = { userList: [], status: "", search_user: "", search_user_name: "" };
  componentDidMount() {
    this.getAllUsers(1, "creditor", "yes");
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.users !== prevProps.users) {
      this.setState({
        userList: this.props.users.list
      });
    }
    if (this.state.status !== prevState.status) {
      this.getAllUsers(
        this.props.pagination.currentPage,
        "creditor",
        this.state.status
      );
    }
  }

  getAllUsers = (
    page,
    role,
    requested,
    status,
    search_user
  ) => {
    if(this.props.role === "admin") {
      return this.props.getAllUsers(page, role, requested, status, search_user);
    } else {
      return this.props.getAllUsersEditor(page, role, requested, status, search_user);
    }
  }
  
  renderList = users => {
    if (users.length === 0) {
      return (
        <tr>
          <td>
            <Translate content="message.no_user" />
          </td>
        </tr>
      );
    } else {
      return users.map((user, index) => {
        const {
          email,
          id,
          name,
          phone_number,
          status,
          street_address,
          created_on,
          registered_domain,
          profile_pic_link
        } = user;
        return (
          <tr key={index}>
            <td>
              <img
                className="circle mr-2"
                src={
                  profile_pic_link ? profile_pic_link : "/assets/img/user.png"
                }
                alt="alt"
                width="30px"
                height="30px"
              />
              <Link
                to={{
                  pathname: "/user/detail",
                  state: { id: id, from: "creditor" }
                }}
              >
                {name}
              </Link>
            </td>
            <td>
              {street_address || <Translate content="message.not_available" />}
            </td>
            <td className="PH-text-right">{email}</td>
            <td className="PH-text-right">
              {phone_number || <Translate content="message.not_available" />}
            </td>
            <td className="PH-text-right">
              <Moment format="DD.MM.YYYY">{created_on}</Moment>
            </td>
            <td className="PH-text-right">{registered_domain}</td>
            <td className="PH-text-right">
              <span className="badge badge-unverified">Verified</span>
            </td>
            {/* {status === 'accepted' ? <td className="align-left"><span className="badge badge-success">Accepted</span></td> : null}
            {status === 'new' ? <td className="align-left"><span className="badge badge-primary">New</span></td> : null}
            {status === 'rejected' ? <td className="align-left"><span className="badge badge-danger">Rejected</span></td> : null}
            {status === 'open' ? <td className="align-left"><span className="badge badge-open">Open</span></td> : null}
            {status === 'offer-accepted' ? <td className="align-left"><span className="badge badge-warning">Open</span></td> : null} */}
            {status === "accepted" ? (
              <td className="align-left">
                <span className="badge badge-success">
                  {" "}
                  <Translate content="label.accepted" />
                </span>
              </td>
            ) : null}
            {status === "new" ? (
              <td className="align-left">
                <span className="badge badge-primary">
                  {" "}
                  <Translate content="label.new" />
                </span>
              </td>
            ) : null}
            {status === "rejected" ? (
              <td className="align-left">
                <span className="badge badge-danger">
                  <Translate content="label.rejected" />
                </span>
              </td>
            ) : null}
            {status === "open" ? (
              <td className="align-left">
                <span className="badge badge-open">
                  <Translate content="label.open" />
                </span>
              </td>
            ) : null}
          </tr>
        );
      });
    }
  };
  render() {
    const { totalPage } = this.props.pagination;
    return (
      <Fragment>
        <div className="content-head">
          <div className="content-head-left">
            <h1 className="content-head__title">
              <Translate content="sidebar.account_request" />
            </h1>
          </div>
          <div className="content-head-right">
            <Loading />
            <form className="form-filter d-flex" action="">
              <div className="item">
                {/* <div className="form-group">
                  <select className="form-control" name="select" onChange={(e) => {
                    this.setState({ status: e.target.value })
                  }}>
                    <option value=''>All</option>
                    <option value="open">Open</option>
                    <option value="accepted">Accepted</option>
                    <option value="rejected">Rejected</option>
                    <option value="new">New</option>
                  </select>
                </div> */}
              </div>
              <div className="item mr-3">
                <div className="form-group">
                  <Translate
                    component="input"
                    className="form-control"
                    type="text"
                    name="search-text"
                    attributes={{ placeholder: "placeholder.search" }}
                    onChange={e =>
                      this.setState({ search_user_name: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="item">
                <div className="form-group">
                  <Translate
                    content="button.search"
                    component="button"
                    className="btn btn-primary mr-sm-2 w-100"
                    onClick={e => {
                      e.preventDefault();
                      (async () => {
                        await this.setState({
                          search_user: this.state.search_user_name
                        });
                        this.getAllUsers(
                          1,
                          "creditor",
                          "yes",
                          "",
                          this.state.search_user
                        );
                      })();
                    }}
                  />
                </div>
              </div>
              {/* <div className="item"><a className="btn btn-primary" href="add-new-products.html">Search</a></div> */}
            </form>
          </div>
        </div>
        <div className="content-body">
          <table
            className="table tablesaw-stack"
            data-tablesaw-mode="stack"
            data-tablesaw-minimap="data-tablesaw-minimap"
          >
            <thead>
              <tr>
                <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                  <Translate content="column.name" />
                </th>
                <th
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  data-tablesaw-priority="persist"
                  scope="col"
                >
                  <Translate content="column.city" />
                </th>
                <th
                  className=""
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.email" />
                </th>
                <th
                  className=""
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="label.phonenumber" />
                </th>
                <th
                  className="PH-text-right"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.createdon" />
                </th>
                <th
                  className=""
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.domain" />
                </th>
                <th
                  className="text-right-piehub-table"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  Status
                </th>
                {/* <th className="text-right" data-tablesaw-sortable-col="data-tablesaw-sortable-col" scope="col"><Translate content='column.account_status' /></th> */}
              </tr>
            </thead>
            <tbody>{this.renderList(this.state.userList)}</tbody>
          </table>
          <Pagination
            totalPage={totalPage}
            url={page =>
              this.getAllUsers(
                page,
                "creditor",
                "yes",
                "",
                this.state.search_user
              )
            }
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users,
    pagination: state.pagination,
    role: state.auth.role,
  };
}
export default connect(mapStateToProps, { getAllUsers, getAllUsersEditor })(AccountRequest);
