import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import Translate from "react-translate-component";
import { inputField } from "../formFields";
import * as validation from "../../utils/validate";
import { signin } from "../../actions/login";
import { signinEditor } from "../../actions/Editor/login";
import Loading from "../general/Loading";
class Login extends Component {
  state = { portal: "admin"};
  onSubmit = formProps => {
    if( this.state.portal === "admin") {
      this.props.signin(formProps, () => {
        let redirect = localStorage.getItem("redirect");
        if (redirect !== null) {
          this.props.history.push(redirect);
          localStorage.removeItem("redirect");
        } else {
          this.props.history.push("/creditor/accounts");
        }
      });
    } else {
      console.log("editor login");
      this.props.signinEditor(formProps, () => {
        let redirect = localStorage.getItem("redirect");
        if (redirect !== null) {
          this.props.history.push(redirect);
          localStorage.removeItem("redirect");
        } else {
          this.props.history.push("/creditor/accounts");
        }
      });
    }
    
  };
  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="admin-signin">
        <div className="container-fluid container-full-height">
          <div className="container-full-height">
            <div className="signin-form-container">
              <div className="singin-container">
                <header className="page-header">
                  <img src="/assets/img/logo.png" alt="" />
                  <h1 className="page-title">
                    <Translate content="label.login" />
                  </h1>
                </header>
                <Loading />
                <form
                  className="form-signin"
                  onSubmit={handleSubmit(this.onSubmit)}
                >
                  <div className="form-group">
                    <Translate content="label.loginPortal" component="label" />
                    <select
                      className="form-control"
                      name="select"
                      onChange={e => {
                        this.setState({ portal: e.target.value });
                        console.log(e.target.value);
                      }}
                    >
                      <Translate
                        component="option"
                        attributes={{ label: "label.admin" }}
                        value="admin"
                      />
                      <Translate
                        component="option"
                        attributes={{ label: "label.editors" }}
                        value="editor"
                      />
                    </select>
                  </div>
                  <div className="form-group">
                    <Translate content="label.emailaddress" component="label" />
                    <Field
                      name="email"
                      type="email"
                      component={inputField}
                      className="form-control"
                      validate={[validation.email, validation.required]}
                    />
                  </div>
                  <div className="form-group">
                    <Translate content="label.password" component="label" />
                    <Field
                      name="password"
                      type="password"
                      component={inputField}
                      className="form-control"
                    />
                  </div>
                  {/* <div className="form-group"><a className="signin-forgot" href="">Forgot password?</a></div> */}
                  {this.props.errMsg ? (
                    <font color="red">
                      {this.props.errMsg} <br />
                    </font>
                  ) : null}
                  <button className="btn btn-primary btn-form" type="submit">
                    <Translate content="label.login" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { errMsg: state.error };
}
export default reduxForm({
  form: "login"
})(connect(mapStateToProps, { signin, signinEditor })(Login));
