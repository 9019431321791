import client from "./index";
import { routes } from "./../_api/routes";
import {
  ERROR,
  ALL_CREDIT_APPLICATIONS,
  APPLICATION_DETAIL,
  MATCHED_LIST,
  PAGINATION,
  LOADING,
  LOADING_DONE
} from "./types";
export const allCreditRequests = (
  page,
  status,
  application_description
) => async dispatch => {
  dispatch({
    type: LOADING,
    payload: {
      URL: `${routes.allCreditRequests}`,
      method: "get",
      data: { page, status }
    }
  });
  try {
    let response;
    if (status && application_description) {
      response = await client.get(
        `${routes.allCreditRequests}?page=${page}&status=${status}&application_description=${application_description}`
      );
    } else if (status) {
      response = await client.get(
        `${routes.allCreditRequests}?page=${page}&status=${status}`
      );
    } else if (application_description) {
      response = await client.get(
        `${routes.allCreditRequests}?page=${page}&application_description=${application_description}`
      );
    } else {
      response = await client.get(`${routes.allCreditRequests}?page=${page}`);
    }

    dispatch({
      type: ALL_CREDIT_APPLICATIONS,
      payload: response.data.data
    });
    dispatch({
      type: PAGINATION,
      payload: response.data.meta
    });
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.allCreditRequests}`,
        method: "get",
        data: { page, status }
      }
    });
  } catch (e) {
    // dispatch({
    //   type: ERROR,
    //   payload: `${e.response.data.error}.`
    // });
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.allCreditRequests}`,
        method: "get",
        data: { page, status }
      }
    });
  }
};

export const creditReqDetail = id => async dispatch => {
  dispatch({
    type: LOADING,
    payload: { URL: `${routes.allCreditRequests}`, method: "get", data: { id } }
  });
  try {
    const response = await client.get(`${routes.allCreditRequests}/${id}`);
    dispatch({
      type: APPLICATION_DETAIL,
      payload: response.data.data
    });
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.allCreditRequests}`,
        method: "get",
        data: { id }
      }
    });
  } catch (e) {
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.allCreditRequests}`,
        method: "get",
        data: { id }
      }
    });
  }
};

export const changeApplicationStatus = (
  id,
  status,
  callback
) => async dispatch => {
  try {
    const response = await client.put(
      `${routes.allCreditRequests}/${id}/status`,
      { status }
    );
    if (response) {
      callback();
    }
  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e.response.data.message
    });
  }
};

export const getMatchedList = (id, product_title) => async dispatch => {
  dispatch({
    type: LOADING,
    payload: { URL: `${routes.allCreditRequests}`, method: "get", data: { id } }
  });
  try {
    let response;
    if (product_title) {
      response = await client.get(
        `${routes.allCreditRequests}/${id}/products?product_title=${product_title}`
      );
    } else {
      response = await client.get(`${routes.allCreditRequests}/${id}/products`);
    }
    dispatch({
      type: MATCHED_LIST,
      payload: response.data.data
    });
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.allCreditRequests}`,
        method: "get",
        data: { id }
      }
    });
  } catch (e) {
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.allCreditRequests}`,
        method: "get",
        data: { id }
      }
    });
  }
};

export const createMatch = (id, value, callback) => async dispatch => {
  try {
    dispatch({
      type: LOADING,
      payload: {
        URL: `${routes.allCreditRequests}`,
        method: "get",
        data: { id, value }
      }
    });
    const response = await client.post(
      `${routes.allCreditRequests}/${id}/products`,
      {
        product_ids: value
      }
    );
    if (response) {
      dispatch({
        type: LOADING_DONE,
        payload: {
          URL: `${routes.allCreditRequests}`,
          method: "get",
          data: { id, value }
        }
      });
      callback();
    }
  } catch (e) {
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.allCreditRequests}`,
        method: "get",
        data: { id, value }
      }
    });
  }
};

export const comment = (id, details) => async dispatch => {
  try {
    let body = new FormData();
    body.set("comment", details.comment);
    const response = await client.post(
      `${routes.allCreditRequests}/${id}/comments`,
      body
    );
    if (response) {
      dispatch({
        type: ERROR,
        payload: "comment added"
      });
    }
  } catch (error) {}
};
