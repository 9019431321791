import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class emailtemplate extends Component {
  render() {
    return [
      <div className="content-head">
        <div className="content-head-left">
          <h1 className="content-head__title">Email Template</h1>
        </div>

        <div className="content-head-right">
          <Link className="btn btn-primary" to="">
            Add new Template
          </Link>
        </div>
      </div>,
      <div className="content-body">
        <table
          className="table tablesaw-stack"
          data-tablesaw-mode="stack"
          data-tablesaw-minimap="data-tablesaw-minimap"
        >
          <thead>
            <tr>
              <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                Name
              </th>
              <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                Subject
              </th>
              <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                Status
              </th>
              <th
                className="text-right PH-text-right"
                data-tablesaw-sortable-col="data-tablesaw-sortable-col"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="res-p-l-1">Ask Listing Question</td>
              <td className="email-subject PH-text-right">
                Ask seller a listing question
              </td>
              <td>
                <span className="badge badge-success w-auto float-left">
                  Active
                </span>
              </td>
              <td>
                <div className="text-right PH-text-right d-flex">
                  <Link
                    to="#"
                    className="d-inline-flex mr-2 align-items-center"
                  >
                    <i class="bx bxs-edit"></i>
                    Edit
                  </Link>
                  <Link to="#" className="d-inline-flex align-items-center">
                    <i class="bx bxs-trash-alt"></i>
                    Remove
                  </Link>
                </div>
              </td>
            </tr>

            <tr>
              <td className="res-p-l-1">Account Confirmation</td>
              <td className="email-subject PH-text-right">
                combined with a handful of model..
              </td>
              <td>
                <span className="badge badge-success w-auto float-left">
                  Active
                </span>
              </td>
              <td>
                <div className="text-right PH-text-right">
                  <Link
                    to="#"
                    className="d-inline-flex mr-2 align-items-center"
                  >
                    <i class="bx bxs-edit"></i>
                    Edit
                  </Link>
                  <Link to="#" className="d-inline-flex align-items-center">
                    <i class="bx bxs-trash-alt"></i>
                    Remove
                  </Link>
                </div>
              </td>
            </tr>

            <tr>
              <td className="res-p-l-1">Important Announcement</td>
              <td className="email-subject PH-text-right">
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old...
              </td>
              <td>
                <span className="badge badge-success w-auto float-left">
                  Active
                </span>
              </td>
              <td>
                <div className="text-right PH-text-right">
                  <Link
                    to="#"
                    className="d-inline-flex mr-2 align-items-center"
                  >
                    <i class="bx bxs-edit"></i>
                    Edit
                  </Link>
                  <Link to="#" className="d-inline-flex align-items-center">
                    <i class="bx bxs-trash-alt"></i>
                    Remove
                  </Link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>,
      <div class="content-footer">
        <nav class="nav-content">
          <ul class="pagination">
            <li class="page-item">
              <button class="page-link">
                <i class="bx bx-chevron-left"></i>
              </button>
            </li>
            <li class="page-item active">
              <button class="page-link">1</button>
            </li>
            <li class="page-item">
              <button class="page-link">2</button>
            </li>
            <li class="page-item">
              <button class="page-link">3</button>
            </li>
            <li class="page-item">
              <button class="page-link">4</button>
            </li>
            <li class="page-item">
              <button class="page-link">
                <i class="bx bx-chevron-right"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    ];
  }
}
