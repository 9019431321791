import client from "./index";
import { routes } from "./../_api/routes";
import { GET_PROFILE, ERROR, LOADING, LOADING_DONE } from "./types";

export const getProfile = id => async dispatch => {
  try {
    dispatch({
      type: LOADING,
      payload: { URL: routes.getProfile, method: "get", data: id }
    });
    const response = await client.get(routes.getProfile);

    let responseData = response.data.data;
    responseData.document = response.data.data.document_link;

    dispatch({
      type: GET_PROFILE,
      payload: responseData
    });
    dispatch({
      type: LOADING_DONE,
      payload: { URL: routes.getProfile, method: "get", data: id }
    });
  } catch (e) {
    dispatch({
      type: LOADING_DONE,
      payload: { URL: routes.getProfile, method: "get", data: id }
    });
  }
};

export const editProfile = (details, callback) => async dispatch => {
  try {
    dispatch({
      type: LOADING,
      payload: { URL: routes.getProfile, method: "post", data: details }
    });
    var body = new FormData();

    Object.keys(details).forEach(field => {
      if (
        field !== "company_logo_link" &&
        field !== "profile_pic_link" &&
        field !== "status" &&
        field !== "id" &&
        field !== "document_link"
      ) {
        body.append(`${field}`, details[`${field}`]);
      }
    });

    if (details.document) {
      details.document.map((file, index) => body.append("document", file));
    } else {
      body.append("document", null);
    }

    if (details.company_logo) {
      body.append("company_logo", details.company_logo);
    } else {
      body.append("company_logo", null);
    }
    body.append("profile_pic", null);
    body.delete("category");
    body.append("_method", "put");
    const response = await client.post(routes.getProfile, body);
    if (response) {
      dispatch({
        type: LOADING_DONE,
        payload: { URL: routes.getProfile, method: "post", data: details }
      });
      callback();
    }
  } catch (e) {
    dispatch({
      type: ERROR,
      payload: [e.response.data.message]
    });
    dispatch({
      type: LOADING_DONE,
      payload: { URL: routes.getProfile, method: "post", data: details }
    });
  }
};
