import client from "./index";
import { routes } from "../_api/routes";
import {
  LOADING,
  NOTIFICATION_COUNT,
  ERROR,
  NOTIFICATION_LIST,
  LOADING_DONE
} from "./types";

export const getNotificationCount = () => async dispatch => {
  try {
    const response = await client.get(routes.countNotifications);
    dispatch({
      type: NOTIFICATION_COUNT,
      payload: response.data
    });
  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e.response
        ? e.response.data.message
        : "Unable to fetch notification count"
    });
  }
};

export const getNotificationList = page => async dispatch => {
  try {
    dispatch({
      type: LOADING,
      payload: {
        URL: `${routes.getNotificationList}`,
        method: "get",
        data: null
      }
    });
    const response = await client.get(
      `${routes.getNotificationList}?page=${page}`
    );
    dispatch({
      type: NOTIFICATION_LIST,
      payload: response.data.data
    });
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.getNotificationList}`,
        method: "get",
        data: null
      }
    });
  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e.response
        ? e.response.data.message
        : "Unable to fetch notifications"
    });
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.getNotificationList}`,
        method: "get",
        data: null
      }
    });
  }
};

export const markAsRead = (id, callback) => async dispatch => {
  try {
    let ids = { notification_ids: [id] };
    const response = await client.post(routes.markasRead, ids);
    if (response) {
      callback();
    }
  } catch (e) {}
};
