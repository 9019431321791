import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Translate from "react-translate-component";
import { getAllUsers } from "../../actions/users";
import { getAllUsersEditor } from "../../actions/Editor/users";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Pagination from "../general/Pagination";
import Loading from "../general/Loading";
class ListAdmins extends Component {
  state = { userList: [], status: null, search: "" };
  componentDidMount() {
    this.getAllUsers(1, "admin");
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.users !== prevProps.users) {
      this.setState({
        userList: this.props.users.list
      });
    }
    if (this.state.status !== prevState.status) {
      this.getAllUsers(1, "admin", this.state.status);
    }
  }

  getAllUsers = (
    page,
    role,
    requested,
    status,
    search_user
  ) => {
    if(this.props.role === "admin") {
      return this.props.getAllUsers(page, role, requested, status, search_user);
    } else {
      return this.props.getAllUsersEditor(page, role, requested, status, search_user);
    }
  }
  
  renderList = users => {
    if (users.length === 0) {
      return (
        <tr>
          <td>
            <Translate content="message.no_user" />
          </td>
        </tr>
      );
    } else {
      return users.map((user, index) => {
        const {
          email,
          name,
          phone_number,
          status,
          street_address,
          created_on
        } = user;
        return (
          <tr key={index}>
            <td className="res-p-l-1">{name}</td>
            <td>
              {street_address || <Translate content="message.not_available" />}
            </td>
            <td className="PH-text-right">{email}</td>
            <td className="PH-text-right">{phone_number}</td>
            <td className="PH-text-right">
              <Moment format="DD.MM.YYYY">{created_on}</Moment>
            </td>
            {status === "accepted" ? (
              <td className="align-left">
                <span className="badge badge-success">
                  {" "}
                  <Translate content="label.accepted" />
                </span>
              </td>
            ) : null}
            {status === "new" ? (
              <td className="align-left">
                <span className="badge badge-primary">
                  {" "}
                  <Translate content="label.new" />
                </span>
              </td>
            ) : null}
            {status === "rejected" ? (
              <td className="align-left">
                <span className="badge badge-danger">
                  <Translate content="label.rejected" />
                </span>
              </td>
            ) : null}
            {status === "open" ? (
              <td className="align-left">
                <span className="badge badge-open">
                  <Translate content="label.open" />
                </span>
              </td>
            ) : null}
          </tr>
        );
      });
    }
  };
  render() {
    const { totalPage } = this.props.pagination;
    let filetedUser = this.state.userList.filter(user => {
      return (
        user.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
      );
    });
    return (
      <Fragment>
        <div className="content-head">
          <div className="content-head-left">
            <h1 className="content-head__title">
              <Translate content="label.admin" />
            </h1>
          </div>
          <div className="content-head-right">
            <Link to="/admin/add-admin" className="btn btn-primary">
              <Translate content="label.add_new_admin" />
            </Link>
          </div>
        </div>
        <div className="content-body">
          <Loading />
          <table
            className="table tablesaw-stack"
            data-tablesaw-mode="stack"
            data-tablesaw-minimap="data-tablesaw-minimap"
          >
            <thead>
              <tr>
                <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                  Name
                </th>
                <th
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  data-tablesaw-priority="persist"
                  scope="col"
                >
                  <Translate content="column.city" />
                </th>
                <th
                  className="PH-text-right"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  Email
                </th>
                <th
                  className="PH-text-right"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="label.phonenumber" />
                </th>
                <th
                  className="PH-text-right"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.createdon" />
                </th>
                <th
                  className="text-right"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.account_status" />
                </th>
              </tr>
            </thead>
            <tbody>{this.renderList(filetedUser)}</tbody>
          </table>
          <Pagination
            totalPage={totalPage}
            url={page => this.getAllUsers(page, "admin")}
          />
        </div>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    users: state.users,
    pagination: state.pagination,
    role: state.auth.role,
  };
}

export default connect(mapStateToProps, { getAllUsers, getAllUsersEditor })(ListAdmins);
