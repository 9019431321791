import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import Translate from "react-translate-component";
import { addAdmin } from "../../actions/users";
import * as validation from "../../utils/validate";
import { inputField, renderPhone } from "../formFields";
import { clearError } from "../../actions/clearError";
import Loading from "../general/Loading";
class AddAdmin extends Component {
  state = { phone: "" };
  componentDidMount() {
    this.props.clearError();
  }
  handleOnChange = value => {
    this.setState({ phone: value });
  };
  onSubmit = formProps => {
    if (!formProps.type) {
      formProps.type = "admin";
      this.props.addAdmin(formProps, () => {
        this.props.history.push("/admin/list");
      });
    }
  };
  showError = errors => {
    if (errors) {
      return errors.map((err, index) => {
        return (
          <span key={index}>
            <font color="red">
              {err}
              <br />
            </font>
          </span>
        );
      });
    }
  };
  render() {
    const { handleSubmit } = this.props;
    return (
      <Fragment>
        <div className="content-head">
          <div className="content-head-left">
            <h1 className="content-head__title">
              <Translate content="label.add_new_admin" />
            </h1>
          </div>
        </div>
        <div className="content-body">
          <Loading />
          <form
            className="form-product-search"
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6">
                <Translate content="label.firstname" component="label" />
                <div className="form-group">
                  <Field
                    name="fname"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="label.firstname"
                    // validate={validation.required}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="form-group">
                  <Translate content="label.lastname" component="label" />
                  <Field
                    name="lname"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="label.lastname"
                    // validate={validation.required}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6">
                <div className="form-group">
                  <label>Email</label>
                  <Field
                    name="email"
                    type="email"
                    component={inputField}
                    className="form-control"
                    placeholder="column.email"
                    validate={[validation.required, validation.email]}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="form-group">
                  <label>
                    <Translate content="label.role" />
                  </label>
                  <input
                    name="type"
                    type="text"
                    className="form-control"
                    value="Admin"
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6">
                <div className="form-group">
                  <Translate content="label.password" component="label" />
                  <Field
                    name="password"
                    type="password"
                    component={inputField}
                    className="form-control"
                    placeholder="label.password"
                    // validate={validation.required}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="form-group">
                  <Translate
                    content="label.confirmpassword"
                    component="label"
                  />
                  <Field
                    name="password_confirmation"
                    type="password"
                    component={inputField}
                    className="form-control"
                    placeholder="label.confirmpassword"
                    validate={validation.required}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6">
                <div className="form-group">
                  <Field
                    label={<Translate content="label.phonenumber" />}
                    component={renderPhone}
                    regions="europe"
                    inputExtraProps={{
                      name: "phone_number",
                      required: true
                    }}
                    name="phone_number"
                    country="de"
                  />
                </div>
              </div>
            </div>

            {/* <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="form-group">
                <label for="">Street</label>
                <input className="form-control" type="text" name="street" />
              </div>
            </div>
          </div> */}
            <button className="btn btn-primary btn-form" type="submit">
              <Translate content="sidebar.add_new_account" />
            </button>
            <br />
            {this.props.errMsg ? this.showError(this.props.errMsg) : null}
          </form>
        </div>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = {};
  errors.fname = validation.required(values.fname);
  errors.lname = validation.required(values.lname);
  errors.email = validation.newEmail(values.email);
  if (!values.email) {
    errors.email = validation.email(values.email);
  }
  if (!values.password) {
    errors.password = validation.required(values.password);
  } else {
    errors.password = validation.password(values.password);
  }
  errors.confirm_password = validation.required(values.password_confirmation);
  if (values.password !== values.password_confirmation) {
    errors.password_confirmation = validation.same(
      values.password_confirmation
    );
  }
  if (!values.phone_number) {
    errors.phone_number = "validation.required";
  }
  return errors;
}
function mapStateToProps(state) {
  return { errMsg: state.error };
}

export default reduxForm({
  validate,
  form: "addAdmin"
})(connect(mapStateToProps, { clearError, addAdmin })(AddAdmin));
