import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { getProfile, editProfile } from "../../actions/profile";
import Translate from "react-translate-component";
import { inputField, ReactUploader } from "../formFields";
import * as validation from "../../utils/validate";
import Loading from "../general/Loading";
import { clearLoading, clearError } from "../../actions/clearError";
class EditProfile extends Component {
  state = { file: null, pic: null };
  componentDidMount() {
    this.props.clearError();
    this.props.clearLoading();
    this.props.getProfile();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.file !== prevState.file) {
      this.setState({
        pic: URL && this.state.file ? URL.createObjectURL(this.state.file) : ""
      });
    }
  }
  onSubmit = formProps => {
    if (this.state.file) {
      formProps.company_logo = this.state.file;
    }
    this.props.editProfile(formProps, () =>
      this.props.history.push("/user/profile")
    );
  };
  render() {
    const { handleSubmit } = this.props;
    console.log("render_initialvalues", this.props.initialValues);

    return (
      <Fragment>
        <div className="content-head">
          <div className="content-head-left w-100">
            <div className="d-flex align-items-center editprofile-icon">
              <div className="item position-relative mr-5 company-image--icon">
                {this.props.initialValues ? (
                  <Fragment>
                    <img
                      className="company-logo"
                      src={
                        this.state.pic ||
                        this.props.initialValues.company_logo_link ||
                        null
                      }
                      alt="alt"
                      width="120px"
                      height="120px"
                    />
                    <img
                      className="verify"
                      src="/assets/img/verify.png"
                      alt="alt"
                    />
                  </Fragment>
                ) : null}
              </div>
              <div className="item position-relative">
                <input
                  className="btn btn-outline-light opacity-none position-absolute z-index-1"
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg"
                  name=""
                  onChange={e => this.setState({ file: e.target.files[0] })}
                />
                <span className="btn btn-outline-light position-absolute btn-change-profile">
                  <Translate content="placeholder.changeProfilePic" />{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <Loading />
          <form className="form-signup" onSubmit={handleSubmit(this.onSubmit)}>
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <Translate content="label.companyName" component="label" />
                  <Field
                    name="company_name"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="Company Name inc."
                    validate={validation.required}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <Translate content="label.headquarter" component="label" />
                  <Field
                    name="headquarter"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="Berlin"
                    validate={validation.required}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12 col-sm-6 col-md-6">
                <div className="form-group">
                  <Translate content="label.streetAddress" component="label" />
                  <Field
                    name="street_address"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="High Street"
                    validate={validation.required}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6">
                <div className="form-group">
                  <Translate content="label.postalCode" component="label" />
                  <Field
                    name="zip_code"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="64295"
                    validate={validation.required}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="">
                  <Translate content="label.contactPerson" />
                </label>
                <div className="form-group">
                  <Field
                    name="contact_name_1"
                    type="text"
                    component={inputField}
                    className="form-control mb-2"
                    placeholder="placeholder.contactName"
                    validate={validation.required}
                  />
                  <Field
                    name="contact_email_1"
                    type="email"
                    component={inputField}
                    className="form-control mb-2"
                    placeholder="placeholder.contactEmail"
                    validate={validation.required}
                  />

                  <Field
                    name="contact_phone_no_1"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.contactPhoneNo"
                    validate={validation.required}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="">
                  <Translate content="label.contactPerson" />
                </label>
                <div className="form-group">
                  <Field
                    name="contact_name_2"
                    type="text"
                    component={inputField}
                    className="form-control mb-2"
                    placeholder="placeholder.contactName2"
                  />
                  <Field
                    name="contact_email_2"
                    type="email"
                    component={inputField}
                    className="form-control mb-2"
                    placeholder="placeholder.contactEmail2"
                  />

                  <Field
                    name="contact_phone_no_2"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.contactPhoneNo2"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="">
                  <Translate content="label.contactPerson" />
                </label>
                <div className="form-group">
                  <Field
                    name="contact_name_3"
                    type="text"
                    component={inputField}
                    className="form-control mb-2"
                    placeholder="placeholder.contactName3"
                  />
                  <Field
                    name="contact_email_3"
                    type="email"
                    component={inputField}
                    className="form-control mb-2"
                    placeholder="placeholder.contactEmail3"
                  />

                  <Field
                    name="contact_phone_no_3"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.contactPhoneNo3"
                  />
                </div>
              </div>
            </div>
            <div className="edit-social-media mt-4">
              <label htmlFor="">
                <Translate content="label.socialMedia" />{" "}
              </label>
              <div className="row edit-social-forms">
                <div className="form-group col-md-3">
                  <Field
                    label="Homepage"
                    name="homepage_link"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.enterYourWebsite"
                  />
                </div>
                <div className="form-group col-md-3">
                  <Field
                    label="Facebook"
                    name="facebook_link"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.facebookURL"
                  />
                </div>
                <div className="form-group col-md-3">
                  <Field
                    label="Twitter"
                    name="twitter_link"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.twitterURL"
                  />
                </div>
                <div className="form-group col-md-3">
                  <Field
                    label="Linkedin"
                    name="linked_in_link"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.linkedinURL"
                  />
                </div>
                <div className="form-group col-md-3">
                  <Field
                    label="Xing"
                    name="xing_link"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="placeholder.xingURL"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col">
                <div className="form-group">
                  <strong>
                    {" "}
                    <Translate content="label.fileUpload" component="label" />
                  </strong>
                  <Field
                    name="document"
                    component={ReactUploader}
                    type="file"
                    className="file-uploader file-uploader--small dropzone"
                  />
                  {/* {files ? this.displayFiles(files) : null} */}
                </div>
              </div>
            </div>
            <button className="btn btn-primary">
              <Translate content="label.update" />
            </button>
          </form>
        </div>
      </Fragment>
    );
  }
}

EditProfile = reduxForm({
  form: "EditProfile",
  enableReinitialize: true
})(EditProfile);
const mapStateToProps = state => {
  return { initialValues: state.profile };
};
export default connect(mapStateToProps, {
  getProfile,
  editProfile,
  clearLoading,
  clearError
})(EditProfile);
