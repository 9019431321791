import React, { Component } from "react";

export default class addservices extends Component {
  render() {
    return [
      <div className="content-head">
        <div className="content-head-left">
          <h1 className="content-head__title">Add New Service</h1>
        </div>
      </div>,

      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <div>
              <label>
                <span>Service Name</span>
              </label>
              <input className="form-control" type="text"></input>
            </div>
          </div>
          <div className="form-group">
            <strong className="d-block">Status</strong>
            <div className="form-check form-check-inline pt-3">
              <div>
                <input
                  type="radio"
                  id="status-active"
                  className="form-check-input"
                  name="status"
                ></input>

                <label className="form-check-label" for="status-active">
                  Active
                </label>
              </div>

              <div className="ml-4">
                <input
                  type="radio"
                  id="status-inactive"
                  className="form-check-input"
                  name="status"
                ></input>

                <label className="form-check-label" for="status-inactive">
                  Inactive
                </label>
              </div>
            </div>
          </div>

          <button className="btn btn-primary">Save</button>
        </div>
      </div>
    ];
  }
}
