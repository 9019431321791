import React from "react";

export const selectElement = (array, element) => {
  var filtered = array.filter(function(value, index, arr) {
    return value !== element;
  });
  if (filtered.length === array.length) {
    filtered.push(element);
  }
  return filtered;
};

export const dDigit = number => {
  return (number = ("0" + number).slice(-2));
};

export const ToEuro = ({ amount }) => {
  return (
    <div>
      €
      {amount
        ? Number(amount).toLocaleString("de-DE", { maximumFractionDigits: 0 })
        : 0}
    </div>
  );
};
