import React, { Component } from "react";
import Translate from "react-translate-component";
import { Link } from "react-router-dom";

const token = localStorage.getItem("token");
export default class Error404 extends Component {
  render() {
    return (
      <div className="error404">
        <img
          src="/assets/img/Intersection.png"
          className="error404-image"
          alt="intersection"
        />

        <div className="error404-content">
          <img src="/assets/img/error404.png" alt="error404" />
          <Translate content="label.weCantseem" component="h4" />
          {token ? (
            <Link to="/" className="btn btn-primary mt-3">
              <Translate content="label.takeMeHome" />
            </Link>
          ) : (
            <Link to="/" className="btn btn-primary mt-3">
              <Translate content="label.login" />
            </Link>
          )}
        </div>
      </div>
    );
  }
}
