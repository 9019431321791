import { ALL_PRODUCTS } from "../actions/types";
const initialState = {
  list: []
};
export default function(state = initialState, action) {
  switch (action.type) {
    case ALL_PRODUCTS:
      return { ...state, list: action.payload };
    default:
      return state;
  }
}
