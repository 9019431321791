const API_URL = process.env.REACT_APP_EDITOR_API_URL;
//let API_URL = "http://api.credittech.test/api";
export const routes = {
  login: `${API_URL}/login`,
  logout: `${API_URL}/logout`,
  allUsers: `${API_URL}/editor/users`,
  allCreditRequests: `${API_URL}/editor/applications`,
  allProducts: `${API_URL}/editor/products`,
  getProductApplicationList: `${API_URL}/editor/product`,
  downloadFile: `${API_URL}/download`,
  downloadToken: `${API_URL}/download-token`,
  getProfile: `${API_URL}/me`,
  getNotificationList: `${API_URL}/me/notifications`,
  countNotifications: `${API_URL}/me/notification/count-new`,
  markasRead: `${API_URL}/me/notification/read`,
  passwordReset: `${API_URL}/password-reset-token`,
  changePasswordWithToken: `${API_URL}/change-password-with-token`,
  comfirmLink: `${API_URL}/confirmation-link`
};
