import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/flashMessage";
import Translate from "react-translate-component";

class FlashMessage extends Component {
render() {
    return (
    <Fragment>
    {
        this.props.data.visible ?
        <div
            className={
            this.props.data.visible
                ? "alert alert-primary"
                : "hidden alert alert-primary"
            }
        >
            <Translate content={this.props.data.message} />
        </div> : null
    }
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { data: state.flashMessage };
}

export default connect(mapStateToProps, actions)(FlashMessage);
