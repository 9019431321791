import React, { Component } from "react";

export class popup extends Component {
  render() {
    return (
      <div className="popup">
        <div className="popup-header">
          <span className="popup-header-title">Confirm</span>
          <span class="close">&times;</span>
        </div>
        <div className="popup-content">
          <img src="/assets/img/confirmation.png" alt="confirmation" />
          <span className="popup-content-title">
            Are you sure want to remove the user?
          </span>
          <span className="popup-content-subtitle">
            Press OK to continue, or Cancel to stay on the current page
          </span>
        </div>
        <div className="popup-footer">
          <button className="btn btn-primary mr-4">ok</button>
          <button className="btn ">Cancel</button>
        </div>
      </div>
    );
  }
}

export default popup;
