import { APPLICATION_DETAIL } from "../actions/types";
const initialState = {
  detail: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case APPLICATION_DETAIL:
      return { ...state, detail: action.payload };
    default:
      return state;
  }
}
