import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import Translate from "react-translate-component";
import { userDetail, changeUserStatus, updateApplicationLimit } from "../../actions/users";
import { changeUserStatusEditor, userDetailEditor, updateApplicationLimitEditor } from "../../actions/Editor/users";
import Loading from "../general/Loading";
import UserConfirmation from "./UserConfirmation";
import { confirmationLink } from "../../actions/confirmationLink";
import { allProducts } from "../../actions/product";
import { allProductsEditor } from "../../actions/Editor/product";
import ProductList from "../investor/products/ProductList";
import FileList from "../general/FileList";
import {clearError} from "../../actions/clearError";
import ApplicationLimit from "../creditor/ApplicationLimit";
class UserDetail extends Component {
  state = { userDetail: null, application_limit:0 };
  async componentDidMount() {
    const { id } = this.props.location.state;
    if(this.props.role === "admin") {
      await this.props.userDetail(id);
    } else {
      await this.props.userDetailEditor(id);
    }
    this.setState({userDetail:this.props.userDetails.detail, application_limit:this.props.userDetails.detail.application_limit});
  }

  changeUserStatus = (
    id,
    status,
    role,
    callback
  ) => {
    if(this.props.role === "admin") {
      return this.props.changeUserStatus(id, status, role, callback);
    } else {
      return this.props.changeUserStatusEditor(id, status, role, callback);
    }
  }

  updateApplicationLimit = (id, application_limit, callback) => {
    if(this.props.role === "admin") {
      return this.props.updateApplicationLimit(id, application_limit, callback);
    } else {
      return this.props.updateApplicationLimitEditor(id, application_limit, callback);
    }
  }

  render() {
    if (this.state.userDetail) {
      const {
        category,
        company_logo_link,
        company_name,
        contact_email_1,
        contact_email_2,
        contact_email_3,
        contact_name_1,
        contact_name_2,
        contact_name_3,
        contact_phone_no_1,
        contact_phone_no_2,
        contact_phone_no_3,
        email,
        facebook_link,
        name,
        headquarter,
        id,
        linked_in_link,
        phone_number,
        status,
        street_address,
        twitter_link,
        zip_code,
        homepage_link,
        xing_link,
        registered_domain,
        documents
      } = this.state.userDetail;
      return (
        <Fragment>
          <div className="content-head">
            <div className="content-head-left w-50">
              <div className="d-flex company-image">
                <div className="item position-relative">
                  <img
                    src={
                      company_logo_link
                        ? company_logo_link
                        : "/assets/img/user.png"
                    }
                    className="company-logo"
                    width="120px"
                    height="120px"
                    alt="company logo"
                  />
                  <img
                    className="verify"
                    src="/assets/img/verify.png"
                    alt="alt"
                  />
                </div>
                <div className="item ml-4">
                  <h2>
                    {company_name}
                    <span className="badge-primary p-2 rounded ml-2">
                      {(status === "approved" || status === "verified") ? (
                        <Translate content="label.verified" />
                      ) : (
                        <Translate content="label.unverified" />
                      )}
                    </span>
                  </h2>
                  <div className="d-flex social-media mt-2">
                    {homepage_link ? (
                      <a
                        href={getValidURL(homepage_link)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="mr-3"
                          src="/assets/img/domain.png"
                          alt="alt"
                          width="25px"
                          height="25px"
                        />
                      </a>
                    ) : null}
                    {facebook_link ? (
                      <a
                        href={getValidURL(facebook_link)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="mr-3"
                          src="/assets/img/facebook.png"
                          alt="alt"
                          width="25px"
                          height="25px"
                        />
                      </a>
                    ) : null}
                    {twitter_link ? (
                      <a
                        href={getValidURL(twitter_link)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="mr-3"
                          src="/assets/img/twitter.png"
                          alt="alt"
                          width="25px"
                          height="25px"
                        />
                      </a>
                    ) : null}
                    {linked_in_link ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={getValidURL(linked_in_link)}
                      >
                        <img
                          className="mr-3"
                          src="/assets/img/linkedin.png"
                          alt="alt"
                          width="25px"
                          height="25px"
                        />
                      </a>
                    ) : null}
                    {xing_link ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={getValidURL(xing_link)}
                      >
                        <img
                          src="/assets/img/xing.png"
                          alt="alt"
                          width="25px"
                          height="25px"
                        />
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-body mt-5">
            {category ? (
              <div className="d-flex">
                <img src="/assets/img/bx-briefcase.png" alt="" />
                <span className="mr-3 ml-3 font-weight-bold">
                  <Translate content="column.category" />:{" "}
                </span>
                <span>
                  {category
                    ? category.charAt(0).toUpperCase() + category.slice(1)
                    : category}
                </span>
              </div>
            ) : null}
            {street_address && headquarter && zip_code ? (
              <div className="d-flex mt-4">
                <figure className="m-0">
                  <img src="/assets/img/bx-map.png" alt="" />
                </figure>
                <p className="ml-3 font-weight-bold mb-2">
                  {street_address} <br />
                  {headquarter}, {zip_code}
                </p>
              </div>
            ) : null}
            <div className="d-flex mt-4 phone">
              <img src="/assets/img/bx-phone.png" alt="" />
              <div className="d-flex flex-column">
                <h4 className="ml-3 font-weight-bold">
                  {contact_name_1
                    ? contact_name_1
                    : name.charAt(0).toUpperCase() + name.slice(1)}
                </h4>
                <span className="ml-3 mb-2">
                  { registered_domain }
                </span>
                <span className="ml-3 mb-2">
                  {contact_email_1 ? contact_email_1 : email}
                </span>
                <span className="ml-3">
                  {contact_phone_no_1 ? contact_phone_no_1 : phone_number}
                </span>
              </div>
            </div>
            {contact_name_2 ||
            contact_name_3 ||
            contact_phone_no_2 ||
            contact_phone_no_3 ||
            contact_email_3 ||
            contact_email_2 ? (
              <div>
                <div className="d-flex mt-4">
                  <img
                    src="/assets/img/contact-person.png"
                    alt=""
                    width="20px"
                  />
                  <span className="ml-3 font-weight-bold">
                    <Translate content="label.contact_person" />
                  </span>
                </div>
                <div className=" d-flex mt-4 contact_person">
                  <div className="item d-flex flex-column">
                    <h5 className="ml-3 font-weight-normal">
                      {contact_name_2}{" "}
                    </h5>
                    <span className="ml-3 mb-2">{contact_email_2}</span>
                    <span className="ml-3 mb-2">{contact_phone_no_2}</span>
                  </div>
                  <div className="item ml-4 d-flex flex-column">
                    <h5 className="ml-3 font-weight-normal">
                      {contact_name_3}{" "}
                    </h5>
                    <span className="ml-3 mb-2">{contact_email_3}</span>
                    <span className="ml-3 ">{contact_phone_no_3}</span>
                  </div>
                </div>
              </div>
            ) : null}
            {documents.length ? (

            <div className="d-flex mt-4 phone">
              <img
                src="/assets/img/bx-phone.png"
                alt=""
                width="20px"
                height="20px"
              />
              <div className="d-flex flex-column">
                <h4 className="ml-3 font-weight-bold"><Translate content="label.documents" /></h4>
                <FileList documents={documents} />
              </div>
            </div>
            ): (
              <div className="d-flex mt-4 phone">
                <div className="d-flex flex-column">
                  <span className="ml-3 mb-2"><Translate content="column.nodocument" /></span>
                </div>
              </div>
            )}
            {status !== "approved" || status !== "rejected" ? registered_domain !== this.props.registered_domain ? null : (
              <div className="item mt-5">
                {(category && street_address && headquarter && zip_code) ||
                (street_address && headquarter && zip_code) ? (
                  <button
                    className="btn btn-accept"
                    onClick={() =>
                      this.changeUserStatus(
                        id,
                        "accepted",
                        this.props.location.state.from,
                        () => this.props.history.push("/investor/accounts")
                      )
                    }
                  >
                    <Translate content="button.approve" />
                  </button>
                ) : (
                  <button
                    className="btn btn-accept"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    <Translate content="button.approve" />
                  </button>
                )}
                <button
                  className="btn btn-reject ml-4"
                  onClick={() =>
                    this.changeUserStatus(
                      id,
                      "rejected",
                      this.props.location.state.from,
                      () =>
                        this.props.history.push(
                          `/${this.props.location.state.from}/accounts`
                        )
                    )
                  }
                >
                  <Translate content="button.disapprove" />
                </button>
                <button
                  className="btn btn-primary ml-4"
                  onClick={() => {
                    // @todo flash message should be displayed
                    this.props.confirmationLink(email);
                  }}
                >
                  <Translate content="button.resendConfirmation" />
                </button>
              </div>
            ) : null}
          </div>
          <UserConfirmation
            id={this.props.location.state.id}
            from={this.props.location.state.from}
            onApprove={() => {
              this.props.history.push(
                `/${this.props.location.state.from}/accounts`
              );
            }}
          />

          {this.props.location.state.from === "creditor" ? registered_domain !== this.props.registered_domain ? null : (
            <ApplicationLimit application_limit={this.state.application_limit} creditor_id={this.props.location.state.id} onChange={(value)=>{
              this.props.clearError();
              this.setState({application_limit:value});
            }} />
          ):null}

          {this.props.location.state.from === "investor" ? (
            <ProductList investor_id={id} />
          ) : null}
        </Fragment>
      );
    } else {
      return <Loading />;
    }
  }
}
function mapStateToProps(state) {
  return {
    userDetails: state.userDetail,
    products: state.products,
    pagination: state.pagination,
    errors: state.error,
    registered_domain: state.auth.registered_domain,
    role: state.auth.role,
  };
}
function getValidURL(url) {
  let pattern = /^((http|https|ftp):\/\/)/;

  if (!pattern.test(url)) {
    url = "http://" + url;
  }
  return url;
}
export default connect(mapStateToProps, {
  userDetail,
  changeUserStatus,
  allProducts,
  allProductsEditor,
  confirmationLink,
  updateApplicationLimit,
  clearError,
  changeUserStatusEditor, 
  userDetailEditor, 
  updateApplicationLimitEditor
})(UserDetail);
