export default {
  column: {
    name: "Name",
    category: "Kategorie",
    interest: "Zinsen",
    minimum_credit_amount: "Mindestkreditbetrag",
    max_credit_amount: "Höchstkreditbetrag",
    available_credit_amount: "Kreditbetrag",
    status: "Status",
    edit: "Bearbeiten",
    mindestbetrag: "Höchstkreditbetrag",
    duration: "Laufzeit",
    services: "Kreditart",
    creditorsname: "Kreditnehmer",
    industry: "Branche",
    investedon: "Bestätigt am",
    investedamount: "Kreditbetrag",
    numberofrequest: "Anzahl der Anfragen",
    createdon: "Erstellt",
    approvedon: "Bestätigt",
    productname: "Produktname",
    requeston: "Anfrage am",
    requestedby: "Angefragt von",
    requestedamount: "Kreditbetrag",
    credittype: "Kreditprodukt",
    yourejected: "Sie haben diesen Antrag abgelehnt.",
    iagree: "Ich stimme den Geschäftsbedingungen zu.",
    terms: "Geschäftsbedingung",
    addfile: "Dokument auswählen",
    ordrop: "oder Dokument ablegen",
    noattachment: "Keine Anhänge verfügbar",
    appreject: "Sie haben diesen Antrag abgelehnt",
    appaccept: "Sie haben diesen Antrag angenommen",
    norating: "Keine Bewertungen vorhanden",
    minduration: "Mindestlaufzeit",
    maxduration: "Höchstlaufzeit",
    min_sales_creditor: "Mindestumsatz Kreditnehmer",
    city: "Stadt",
    account_status: "Status",
    max_amount: "Höchstkreditbetrag",
    min_amount: "Mindestkreditbetrag",
    email: "E-Mail",
    nodocument: "Keine Dokumente verfügbar",
    domain: "Domain", //Need translations
  },
  sidebar: {
    product: "Kreditprodukte",
    new_product: "Kreditprodukte anlegen",
    invested_products: "Aktive Kredite",
    products: "Kreditprodukte",
    credit_requested_products: "Kreditanträge",
    product_request: "Produktanfragen",
    all_accounts: "Alle Konten",
    add_new_account: "Neues Konto hinzufügen",
    account_request: "Kontoanfrage",
    creditor: "Kreditnehmer",
    credit_requests: "Kreditanträge",
    investor: "Kreditgeber",
    ratings: "Ratings",
    stateCounty: "Bundesland/Landkreis",
    emailTemplate: "Email Template"
  },
  label: {
    Produktdetail: "Produktdetails",
    AngebotErstellt: "Angebot erstellt",
    ZuBearbeiten: "Zu bearbeiten",
    processing: "Zu bearbeiten",
    Kreditorname: "Name Kreditnehmer",
    theperfect: "Die perfekte Investition",
    atcredittech:
      "Bei piHub können Sie leicht einen Kreditnehmer finden und eine Investition tätigen",
    whycredittech: "Warum piHub?",
    atfirst:
      "Zuerst habe ich wenig investiert und mit der Zeit habe ich in den Sektor investiert, den ich mag, und Credit Tech bringt mich genau dorthin",
    whatothers: "Was sagen andere?",
    signupto: "Melden Sie sich als Kreditgeber bei piHub an",
    enteryourdetails: "Geben Sie unten Ihre Daten ein.",
    firstname: "Vorname",
    lastname: "Nachname",
    companyname: "Name der Firma",
    emailaddress: "E-Mail-Adresse",
    password: "Passwort",
    confirmpassword: "Passwort bestätigen",
    phonenumber: "Telefonnummer",
    alreadyhaveanaccount: "Sie haben schon ein Konto?",
    login: "Anmeldung",
    lorem:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.",
    ifyoudont: "Sie haben noch kein Konto? Anmeldung",
    youdont: "Du hast keine (neuen) Benachrichtigungen",
    enteryouremail: "Geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein",
    here: "Hier",
    sendit: "Erneut senden",
    didnt: "Sie haben keine E-Mail erhalten?",
    youraccounthas:
      "Ihr Konto wurde erfolgreich erstellt. Bitte überprüfen Sie Ihre E-Mails, dort finden Sie den Bestätigungslink.",
    weve: "Wir senden Ihnen einfach eine E-Mail",
    activation: "Aktivierung",
    adminapproval: "Administrator-Genehmigung",
    confirmemail: "Bestätigungs-E-Mail",
    theconfirm: "Der Bestätigungslink ist abgelaufen.",
    wecant: "Wir können Ihre E-Mail-Adresse nicht bestätigen.",
    ouradminneed:
      "Unser Administrator muss Ihren Account zuerst bestätigen. Wir senden Ihnen eine E-Mail, sobald dies geschehen ist.",
    justonemore: "Nur noch ein Schritt - die Bestätigung des Administrators.",
    youcanask: "Hier können Sie einen neuen Bestätigungslink anfordern.",
    youraccounthasbeen:
      "Ihr Konto wurde aktiviert. Ab sofort können Sie alle piHub-Funktionen frei nutzen.",
    wow: "Hallo, Willkommen bei piHub",
    attachments: "Anlagen",
    timeduration: "Laufzeit",
    investor: "Investor",
    maxcredit: "Höchstkreditbetrag",
    industries: "Branche",
    state: "Bundesland",
    service: "Kreditart",
    Sicherheiten: "Sicherheiten",
    Ratingagentur: "Ratingagentur",
    producttitle: "Produktname",
    requested: "Angefordert",
    approved: "Genehmigt",
    rejected: "Abgelehnt",
    invested: "Investiert",
    allproducts: "Alle Kreditprodukte",
    fileupload: "Dokumente hochladen",
    no: "Nein",
    yes: "Ja",
    rating: "Mindeskreditrating",
    Kreditrating: "Kreditrating",
    country: "Landkreis",
    editproducts: "Produkt bearbeiten",
    mincredit: "Mindestkreditbetrag",
    notifications: "Benachrichtigungen",
    pagenot: ":( - 404 Seite nicht gefunden.",
    thepage:
      "Die von Ihnen gesuchte Seite wurde möglicherweise entfernt, weil sich Ihr Name geändert hat oder sie ist vorübergehend nicht verfügbar.",
    back: "Zurück zur Startseite",
    logout: "Logout",
    accept: "Angebot erstellen / Absage",
    requestedamount: "Kreditbetrag",
    time: "Zeitdauer",
    deadline: "Fristablauf",
    applicationcode: "Anwendungscode",
    creditrequests: "Kreditanträge",
    youdonot: "Sie haben noch keine Produkte!",
    profile: "Profil",
    editprofile: "Profil bearbeiten",
    minimumsales: "Mindestumsatz Kreditnehmer",
    resetpassword: "Passwort zurücksetzen",
    months: "Monate",
    open: "Öffnen",
    postponed: "Ausgesetzt",
    deleted: "Gelöscht",
    deletedmsg: "Dieses Produkt wurde gelöscht",
    postponedmsg:
      "Dieses Produkt wurde ausgesetzt. Sie können das Produkt auf dieser Seite wieder aktivieren.",
    new: "Neu",
    accepted: "Akzeptiert",
    requested_products: "Angefragte Produkte",
    add_new_account: "Neues Konto hinzufügen",
    type_of_user: "Art des Benutzers",
    requested_account: "Kontoanfragen",
    creditor_all_account: "Alle Konten",
    creditor_requested_accounts: "Kreditanträge",
    offer_accepted: "Angebot angenommen",
    offer_received: "Angebot erhalten",
    offer_rejected: "Angebot abgelehnt",
    questions_for_creditor: "Fragen an den Kreditnehmer",
    verified: "Bestätigt",
    unverified: "Nicht bestätigt",
    contact_person: "Ansprechperson",
    requested_for: "Antrag für",
    credit_request_for: "Kredit beantragt für",
    requested_by: "Angefordert von",
    amount: "Kreditbetrag",
    description: "Beschreibung",
    investment_available_of: "Kredit verfügbar von",
    role: "Rolle",
    add_new_admin: "Neuen Administrator hinzufügen",
    admin: "Administratoren",
    all_admins: "Alle Administratoren",
    salesAmount: "Umsatz Kreditnehmer",
    deadlineForPayment: "Auszahlungsfrist",
    ratingForCredit: "Kreditrating",
    productDetailOf: "Produktdetails",
    all: "Alle",
    questionsForCreditor: "Fragen an den Kreditnehmer",
    offerRejected: "Angebot abgelehnt",
    offerAccepted: "Angebot angenommen",
    matchedProductForRequest: "Treffer für Kreditanfrage",
    creditType: "Kreditart",
    county: "Landkreis",
    minCreditAmount: "Mindestkreditbetrag",
    maxCreditAmount: "Höchstkreditbetrag",
    justASec: "Einen Moment bitte...",
    iAgreeToThe: "Hiermit stimme ich den Geschäftsbedingungen zu.",
    termsAndConditions: " Geschäftsbedingung",
    purpose: "Verwendungszweck",
    nda: "Vertraulichkeitserklärung",
    addInvestorAccount: "Investor hinzufügen",
    addCreditorAccount: "Kreditnehmer hinzufügen",
    companyName: "Name der Firma",
    headquarter: "Hauptsitz",
    streetAddress: "Straße und Hausnummer",
    postalCode: "Postleitzahl",
    contactPerson: "Ansprechpartner",
    socialMedia: "Soziale Medien",
    fileUpload: "Dokumente hochladen",
    update: "ändern",
    editProfile: "Profil ändern",
    changePassword: "Du möchtest dein Passwort ändern?",
    enterAnEmailAssociated:
      "Gib die mit deinem Account verknüpfte Mail Adresse ein.",
    passwordChangedSuccessful: "Erfolgreiche Passwortänderung",
    youCanUseYourNewPassword:
      "Mit Ihrem neuen Passwort können Sie sich bei Ihrem creditech-Konto anmelden.",
    withYourNewPassword: "mit deinem neuen Passwort",
    weCantseem: "Sorry we can't seem to find the page you're looking for",
    takeMeHome: "Take me home",
    documents: "Dokumente",
    setCreditRequest: "Maximale Kreditanforderung festlegen",
    add_new_editor: "Neuen Editor hinzufügen", //Need Translation
    all_editors: "Alle Editors", //Need Translation
    editors: "Editors", //Need Translation
    registered_domain: "Domain", //Need Translation
    matching_option: "Matching Option", //Need Translation
    loginPortal: "Portal", //Need Translation
    resetYourPassword: "Passwort zurücksetzen"
  },
  button: {
    Produktbearbeiten: "Produkt bearbeiten",
    signup: "Jetzt registrieren",
    search: "Suche",
    addnewproduct: "Kreditprodukt hinzufügen",
    submit: "Einreichen",
    edit: "Produkt bearbeiten",
    download: "Herunterladen",
    delete: "Löschen",
    postpone: "Aussetzen",
    undopostpone: "Aussetzen rückgängig machen",
    approve: "Akzeptieren",
    disapprove: "Abglehnen",
    accept: "Akzeptieren",
    reject: "Ablehnen",
    logout: "Ausloggen",
    show_match: "Treffer anzeigen",
    moreDetail: "Mehr Details",
    send: "Senden",
    close: "schließen",
    downloadAll: "alle herunterladen",
    resendConfirmation: "Bestätigung erneut senden"
  },
  placeholder: {
    select: "Auswählen",
    search: "Suche",
    verified: "Bestätigt",
    unverified: "Nicht bestätigt",
    NewPassword: "Neues Passwort",
    Retypepassword: "Gib dein Passwort erneut ein",
    changeProfilePic: "Firmenbild ändern",
    enterYourWebsite: "Gib deine URL ein",
    companyName: "Firmenname",
    headquarterLocation: "Firmensitz",
    contactName: "Name des Ansprechpartners",
    contactName2: "Name des Ansprechpartners-2",
    contactName3: "Name des Ansprechpartners-3",
    contactEmail: "Mail Adresse des Ansprechpartners",
    contactEmail2: "Mail Adresse des Ansprechpartners-2",
    contactEmail3: "Mail Adresse des Ansprechpartners-3",
    contactPhoneNo: "Telefonnummer des Ansprechpartners",
    contactPhoneNo2: "Telefonnummer des Ansprechpartners-2",
    contactPhoneNo3: "Telefonnummer des Ansprechpartners-3",
    facebookURL: "Facebook URL",
    twitterURL: "Twitter URL",
    LinkedinURL: "Linkedin URL",
    xingURL: "Xing URL"
  },
  unverified: {
    msg:
      "Ihr Konto ist noch nicht bestätigt. Solbald dies geschehen ist, werden Sie per E-Mail benachrichtigt. Bis dahin können Sie gerne Ihr Profil vervollständigen."
  },
  message: {
    no_product: "Kein Produkt gefunden",
    no_user: "Kein Konto gefunden",
    no_application: "Keine Anfragen gefunden",
    not_available: "Nicht verfügbar",
    tax: "Steuern können je nach Land anfallen",
    done:"fertig!"
  },
  validation: {
    required: "* Pflichtfeld",
    email: "Ungültige E-Mail-Adresse",
    phoneNumber: "Ungültige Telefonnummer, muss 10-stellig sein",
    number: "* Muss eine Nummer sein",
    same: "Ihre Passworteingaben stimmen nicht überein.",
    password:
      "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, ein Sonderzeichen (@, #, $) und einen Zahlenwert enthalten und aus 8 Ziffern bestehen",
    newEmail: "Ungültige E-Mail!",
    fillAllRequiredFields: "Bitte füllen Sie alle Pflichtfelder aus"
  },
  modal: {
    approveUser: "Benutzer genehmigen",
    userConfirm:
      "Straßenadresse, Hauptsitz und Postleitzahl sind leer. Sind Sie sicher, dass Sie das genehmigen möchten?",
    approveIt: "Ja! Genehmigen Sie es trotzdem"
  },
  alert: {
    user_deleted: "Benutzer Gelöscht!",
    failed_to_delete_creditor: "Der Benutzer kann nicht gelöscht werden, der Benutzer hat Anwendungen!"
  }
};
