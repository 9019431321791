const strongRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);

const emailRule = new RegExp("^[A-Za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{1,4}[^]+$");

export const email = value =>
  value && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
    ? "validation.email"
    : undefined;

export const phoneNumber = value =>
  value &&
  !/^(?=(?:\D*\d){10,15}\D*$)\+?[0-9]{1,3}[\s-]?(?:\(0?[0-9]{1,5}\)|[0-9]{1,5})[-\s]?[0-9][\d\s-]{5,7}\s?(?:x[\d-]{0,4})?$/g.test(
    value
  )
    ? "validation.phoneNumber"
    : undefined;
export const required = value =>
  value || typeof value === "number" ? undefined : "validation.required";

export const number = value =>
  value && isNaN(Number(value)) ? "validation.number" : undefined;

export const same = (value1, value2) =>
  value1 === value2 ? "validation.same" : undefined;

export const password = value =>
  value && !strongRegex.test(value) ? "validation.password" : undefined;

export const newEmail = value =>
  value && !emailRule.test(value) ? "validation.newEmail" : undefined;
