import client from "./index";
import { routes } from "./../_api/routes";
import { ERROR, AUTH_USER, LOADING, LOADING_DONE, AUTH_USER_LOGOUT } from "./types";
import { BroadcastChannel } from "broadcast-channel";

const logoutChannel = new BroadcastChannel("");
export const signin = ({ email, password }, callback) => async dispatch => {
  dispatch({
    type: LOADING,
    payload: { URL: routes.login, method: "post", data: { email, password } }
  });
  try {
    const response = await client.post(routes.login, {
      email,
      password
    });
    localStorage.setItem("token", response.data.message.token);
    localStorage.setItem("role", response.data.message.role);
    localStorage.setItem("registered_domain", response.data.message.registered_domain);
    dispatch({
      type: AUTH_USER,
      payload: response.data.message
    });
    dispatch({
      type: LOADING_DONE,
      payload: { URL: routes.login, method: "post", data: { email, password } }
    });
    callback();
  } catch (e) {
    dispatch({
      type: ERROR,
      payload: `${e.response.data.message}.`
    });
    dispatch({
      type: LOADING_DONE,
      payload: { URL: routes.login, method: "post", data: { email, password } }
    });
  }
};

export const logout = callback => async dispatch => {
  try {
    logoutChannel.postMessage({
      userId: "",
      payload: {
        type: "LOGOUT"
      }
    });
    const response = await client.get(routes.logout);
    dispatch({
      type: AUTH_USER_LOGOUT,
      payload: "",
    });
    if (response) {
      localStorage.removeItem("token");
      localStorage.removeItem("language");
      localStorage.removeItem("registered_domain");
      localStorage.removeItem("role");
      callback();
    }
  } catch (error) {
      localStorage.removeItem("token");
      localStorage.removeItem("language");
      callback();
  }
};

export const logoutAllTabsEventListner = () => async dispatch => {
  logoutChannel.onmessage = data => {
    if (data.payload.type === "LOGOUT") {
      localStorage.removeItem("token");
      localStorage.removeItem("registered_domain");
      localStorage.removeItem("role");
      window.location.href = window.location.origin + "/";
    }
    logoutChannel.close();
  };
};
