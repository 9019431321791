import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Translate from "react-translate-component";
import Loading from "../general/Loading";
import { getNotificationList, markAsRead } from "../../actions/notifications";

class Notifications extends Component {
  state = { refresh: false };

  componentDidMount() {
    //@todo add document.title if the title of the page should be according to the page.
    this.props.getNotificationList(1);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.refresh !== this.state.refresh) {
      this.props.getNotificationList(1);
    }
  }

  renderNotification = notifications => {
    if (notifications.length === 0) {
      return <Translate content="label.youdont" />;
    }
    return notifications.map((notification, index) => {
      let color;
      notification.is_read === 0 ? (color = "sucess") : (color = "wait");
      return (
        <Fragment key={index}>
          <li
            className="notification-item d-flex flex-row align-items-top mb-3 pb-3"
            onClick={() =>
              this.markAsRead(notification.id, notification.is_read)
            }
          >
            <div className={`status ${color} mr-4`} />
            <div className="title">
              <p className="wait">{notification.notification}</p>
              <div className="time">{notification.ago}</div>
            </div>
          </li>
        </Fragment>
      );
    });
  };

  markAsRead = (id, is_read) => {
    if (is_read !== 0) {
      return;
    } else {
      this.props.markAsRead(id, () => {
        this.setState({ refresh: !this.state.refresh });
      });
    }
  };

  render() {
    if (this.props.list) {
      return (
        <Fragment>
          <div className="content-head">
            <div className="content-head-left">
              <Translate
                content="label.notifications"
                component="h1"
                className="content-head__title"
              />
            </div>
          </div>
          <div className="content-body mt-2">
            <Loading />
            <ul className="notification p-0">
              {this.props.list.notificationList
                ? this.renderNotification(this.props.list.notificationList)
                : null}
            </ul>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="content-head">
            <div className="content-head-left">
              <Translate
                content="label.notifications"
                component="h1"
                className="content-head__title"
              />
            </div>
          </div>
          <div className="content-body mt-2">
            <ul className="notification p-0">
              <Loading />
            </ul>
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return { list: state.notificationList };
}

export default connect(mapStateToProps, { getNotificationList, markAsRead })(
  Notifications
);
