import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import Translate from "react-translate-component";
import { inputField, renderPhone } from "../formFields";
import { addUser } from "../../actions/users";
import { addUserEditor } from "../../actions/Editor/users";
import { clearError } from "../../actions/clearError";
import * as validation from "../../utils/validate";
import Loading from "../general/Loading";
class AddAccount extends Component {
  componentDidMount() {
    this.props.clearError();
  }
  onSubmit = formProps => {
    if (!formProps.type) {
      formProps.type =
        this.props.location.pathname === "/creditor/add-account"
          ? "creditor"
          : "investor";
    }
    if(this.props.role === "admin") {
      this.props.addUser(
        formProps,
        () => {
          this.props.history.push("/investor/accounts");
        },
        () => {
          this.props.history.push("/creditor/accounts");
        }
      );
    } else {
      this.props.addUserEditor(
        formProps,
        () => {
          this.props.history.push("/investor/accounts");
        },
        () => {
          this.props.history.push("/creditor/accounts");
        }
      );
    }
  };
  showError = errors => {
    if (errors) {
      return errors.map((err, index) => {
        return (
          <span key={index}>
            <font color="red">
              {err}
              <br />
            </font>
          </span>
        );
      });
    }
  };
  render() {
    const { handleSubmit } = this.props;

    return (
      <Fragment>
        <div className="content-head">
          <div className="content-head-left">
            <h1 className="content-head__title">
              <label>
                {this.props.location.pathname === "/creditor/add-account" ? (
                  <Translate content="label.addCreditorAccount" />
                ) : (
                  <Translate content="label.addInvestorAccount" />
                )}
              </label>
            </h1>
          </div>
        </div>
        <div className="content-body">
          <Loading />
          <form
            className="form-product-search"
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6">
                <div className="form-group">
                  <Translate content="label.firstname" component="label" />
                  <Field
                    name="fname"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="label.firstname"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="form-group">
                  <Translate content="label.lastname" component="label" />
                  <Field
                    name="lname"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="label.lastname"
                    // validate={validation.required}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6">
                <div className="form-group">
                  <Translate content="label.companyname" component="label" />
                  <Field
                    name="company_name"
                    type="text"
                    component={inputField}
                    className="form-control"
                    placeholder="label.companyname"
                    // validate={validation.required}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="form-group">
                  <label>Email</label>
                  <Field
                    name="email"
                    type="email"
                    component={inputField}
                    className="form-control"
                    placeholder="column.email"
                    validate={[validation.required, validation.email]}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6">
                <div className="form-group">
                  <Translate content="label.password" component="label" />
                  <Field
                    name="password"
                    type="password"
                    component={inputField}
                    className="form-control"
                    placeholder="label.password"
                    // validate={validation.required}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="form-group">
                  <Translate
                    content="label.confirmpassword"
                    component="label"
                  />
                  <Field
                    name="password_confirmation"
                    type="password"
                    component={inputField}
                    className="form-control"
                    placeholder="label.confirmpassword"
                    validate={validation.required}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6">
                <div className="form-group">
                  <Field
                    label={<Translate content="label.phonenumber" />}
                    component={renderPhone}
                    regions="europe"
                    inputExtraProps={{
                      name: "phone_number",
                      required: true
                    }}
                    name="phone_number"
                    country="de"
                  />
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <label for="">Street</label>
                  <input className="form-control" type="text" name="street" />
                </div>
              </div>
            </div> */}
            <button className="btn btn-primary btn-form" type="submit">
              <Translate content="sidebar.add_new_account" />
            </button>
            <br />
            {this.props.errMsg ? this.showError(this.props.errMsg) : null}
          </form>
        </div>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = {};
  errors.fname = validation.required(values.fname);
  errors.lname = validation.required(values.lname);
  errors.company_name = validation.required(values.company_name);

  if (!values.email) {
    errors.email = validation.email(values.email);
  } else {
    errors.email = validation.newEmail(values.email);
  }
  if (!values.password) {
    errors.password = validation.required(values.password);
  } else {
    errors.password = validation.password(values.password);
  }

  if (values.password !== values.password_confirmation) {
    errors.password_confirmation = validation.same(
      values.password_confirmation
    );
  } else {
    errors.confirm_password = validation.required(values.password_confirmation);
  }
  if (!values.phone_number) {
    errors.phone_number = "validation.required";
  }
  return errors;
}
function mapStateToProps(state) {
  return { 
    errMsg: state.error,
    role: state.auth.role,
   };
}

export default reduxForm({
  validate,
  form: "addAccount"
})(connect(mapStateToProps, { addUser, addUserEditor, clearError })(AddAccount));
