import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Translate from "react-translate-component";
import Moment from "react-moment";
import { getAllUsers } from "../../actions/users";
import { getAllUsersEditor } from "../../actions/Editor/users";
import Pagination from "../general/Pagination";
import Loading from "../general/Loading";
class RequestedAccounts extends Component {
  state = { users: [], search_user: "", search_user_name: "" };
  componentDidMount() {
    this.getAllUsers(1, "investor", "yes");
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.users !== this.props.users) {
      this.setState({
        users: this.props.users.list
      });
    }
  }
  
  getAllUsers = (
    page,
    role,
    requested,
    status,
    search_user
  ) => {
    if(this.props.role === "admin") {
      return this.props.getAllUsers(page, role, requested, status, search_user);
    } else {
      return this.props.getAllUsersEditor(page, role, requested, status, search_user);
    }
  }

  showAccount = users => {
    if (users.length === 0) {
      return (
        <tr>
          <td>
            <Translate content="message.no_user" />
          </td>
        </tr>
      );
    } else {
      return users.map((user, index) => {
        const {
          created_on,
          email,
          id,
          name,
          phone_number,
          profile_pic_link,
          status,
          registered_domain,
          street_address
        } = user;
        return (
          <tr key={index}>
            <td>
              <img
                className="circle mr-2"
                src={
                  profile_pic_link ? profile_pic_link : "/assets/img/user.png"
                }
                alt="alt"
                width="30px"
                height="30px"
              />
              <Link
                to={{
                  pathname: "/user/detail",
                  state: { id: id, from: "investor" }
                }}
              >
                {name}
              </Link>
            </td>
            <td>
              {street_address || <Translate content="message.not_available" />}
            </td>
            <td className="PH-text-right">
              {email || <Translate content="message.not_available" />}
            </td>
            <td className="PH-text-right">
              {phone_number || <Translate content="message.not_available" />}
            </td>
            <td className="PH-text-right">
              <Moment format="DD.MM.YYYY">{created_on}</Moment>
            </td>
            <td className="PH-text-right">
              {registered_domain || <Translate content="message.not_available" />}
            </td>
            {status === "accepted" ? (
              <td className="align-left">
                <span className="badge badge-success">
                  {" "}
                  <Translate content="label.accepted" />
                </span>
              </td>
            ) : null}
            {status === "new" ? (
              <td className="align-left">
                <span className="badge badge-primary">
                  {" "}
                  <Translate content="label.new" />
                </span>
              </td>
            ) : null}
            {status === "rejected" ? (
              <td className="align-left">
                <span className="badge badge-danger">
                  <Translate content="label.rejected" />
                </span>
              </td>
            ) : null}
            {status === "open" ? (
              <td className="align-left">
                <span className="badge badge-open">
                  <Translate content="label.open" />
                </span>
              </td>
            ) : null}
            {!status ? (
                <td className="PH-text-right">
                <span className="badge">
                  -
                </span>
                </td>
            ) : null}
          </tr>
        );
      });
    }
  };
  render() {
    const { totalPage } = this.props.pagination;
    return (
      <Fragment>
        <div className="content-head">
          <div className="content-head-left">
            <h1 className="content-head__title">
              <Translate content="label.requested_account" />
            </h1>
          </div>
          <div className="content-head-right">
            <Loading />
            <form className="form-filter" action="">
              <div className="row">
                {/* <div className="col-4 mr-3">
                </div> */}
                <div className="col-8 pr-0">
                  <div className="form-group">
                    <Translate
                      component="input"
                      className="form-control"
                      type="text"
                      name="search-text"
                      attributes={{ placeholder: "placeholder.search" }}
                      value={this.state.search_user_name}
                      onChange={e =>
                        this.setState({ search_user_name: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-4">
                    <Translate
                      content="button.search"
                      component="button"
                      className="btn btn-primary col-12"
                      onClick={e => {
                        e.preventDefault();
                        (async () => {
                          await this.setState({
                            search_user: this.state.search_user_name
                          });
                          this.getAllUsers(
                            1,
                            "investor",
                            "yes",
                            "",
                            this.state.search_user
                          );
                        })();
                      }}
                    />
                </div>
              </div>
              {/* <div className="item"><a className="btn btn-primary" href="add-new-products.html">Search</a></div> */}
            </form>
          </div>
        </div>
        <div className="content-body">
          <table
            className="table tablesaw-stack"
            data-tablesaw-mode="stack"
            data-tablesaw-minimap="data-tablesaw-minimap"
          >
            <thead>
              <tr>
                <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                  Name
                </th>
                <th
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  data-tablesaw-priority="persist"
                  scope="col"
                >
                  <Translate content="column.city" />
                </th>
                <th
                  className="PH-text-right"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  Email
                </th>
                <th
                  className="PH-text-right"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="label.phonenumber" />
                </th>
                <th
                  className="PH-text-right"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.createdon" />
                </th>
                <th
                  className="PH-text-right"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.domain" />
                </th>
                <th className="text-right" data-tablesaw-sortable-col="data-tablesaw-sortable-col" scope="col"><Translate content='column.account_status' /></th>

              </tr>
            </thead>
            <tbody>{this.showAccount(this.state.users)}</tbody>
          </table>
          <Pagination
            totalPage={totalPage}
            url={page =>
              this.getAllUsers(
                page,
                "investor",
                "yes",
                "",
                this.state.search_user
              )
            }
          />
        </div>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    users: state.users,
    pagination: state.pagination,
    role: state.auth.role,
  };
}
export default connect(mapStateToProps, { getAllUsers, getAllUsersEditor })(RequestedAccounts);
