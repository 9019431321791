import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { changeUserStatus, userDetail } from "../../actions/users";
import { changeUserStatusEditor, userDetailEditor } from "../../actions/Editor/users";
import Translate from "react-translate-component";

class UserConfirmation extends Component {
  onClick = () => {
    this.changeUserStatus(
      this.props.id,
      "accepted",
      this.props.from,
      () => {
        this.props.onApprove();
      }
    );
  };
  changeUserStatus = (
    id,
    status,
    role,
    callback
  ) => {
    if(this.props.role === "admin") {
      return this.props.changeUserStatus(id, status, role, callback);
    } else {
      return this.props.changeUserStatusEditor(id, status, role, callback);
    }
  }

  userDetail = id => {
    if(this.props.role === "admin") {
      return this.props.userDetail(id);
    } else {
      return this.props.userDetailEditor(id);
    }
  }
  render() {
    return (
      <Fragment>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog pihub-modal" role="document">
            <div className="modal-content">
              <div className="modal-header align-items-center">
                <Translate
                  content="modal.approveUser"
                  component="h1"
                  className="modal-title mt-0 content-head__title"
                />
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <div>
                    <Translate content="modal.userConfirm" component="label" />
                  </div>
                </div>
                <button
                  className="btn btn-accept"
                  onClick={() => this.onClick()}
                  data-dismiss="modal"
                >
                  <Translate content="modal.approveIt" />
                </button>
                <button className="btn btn-reject ml-4" data-dismiss="modal">
                  <Translate content="button.close" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { 
    userDetails: state.userDetail,
    role: state.auth.role,
   };
}

export default connect(mapStateToProps, { userDetail, changeUserStatus, changeUserStatusEditor, userDetailEditor })(
  UserConfirmation
);
