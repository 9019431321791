import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import auth from "./auth";
import error from "./error";
import users from "./users";
import userDetail from "./userDetail";
import creditRequests from "./creditRequests";
import pagination from "./pagination";
import applicationDetail from "./applicationDetail";
import products from "./products";
import productDetail from "./productDetail";
import matchedList from "./matchedList";
import loading from "./loading";
import profile from "./profile";
import notificationCount from "./notificationCount";
import notificationList from "./notificationList";
import language from "./language";
import flashMessage from "./flashMessage";
const appReducer = combineReducers({
  auth,
  error,
  users,
  userDetail,
  creditRequests,
  notificationList,
  notificationCount,
  applicationDetail,
  products,
  pagination,
  productDetail,
  matchedList,
  form: formReducer,
  loading,
  profile,
  language,
  flashMessage
});

const rootReducer = (state, action) => {

  if (action.type === 'LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;