import React, { Component } from "react";

export default class addindustries extends Component {
  render() {
    return [
      // <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
      //   Launch demo modal
      // </button>,
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog pihub-modal" role="document">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <h1 className="modal-title mt-0 content-head__title">
                Add New Industry
              </h1>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <div>
                    <label>
                      <span>Industry Name</span>
                    </label>
                    <input className="form-control" type="text"></input>
                  </div>
                </div>
                <div className="form-group">
                  <strong className="d-block">Status</strong>
                  <div className="form-check form-check-inline pt-3">
                    <div>
                      <input
                        type="radio"
                        id="status-active"
                        className="form-check-input"
                        name="status"
                      ></input>

                      <label className="form-check-label" for="status-active">
                        Active
                      </label>
                    </div>

                    <div className="ml-4">
                      <input
                        type="radio"
                        id="status-inactive"
                        className="form-check-input"
                        name="status"
                      ></input>

                      <label className="form-check-label" for="status-inactive">
                        Inactive
                      </label>
                    </div>
                  </div>
                </div>
              </form>
              <button className="btn btn-primary">Save</button>
            </div>
          </div>
        </div>
      </div>
    ];
  }
}
