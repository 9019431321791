import { ERROR, CLEAR_LOADING } from "./types";

export const clearError = () => {
  return {
    type: ERROR,
    payload: null
  };
};

export const clearLoading = () => {
  return {
    type: CLEAR_LOADING,
    payload: []
  };
};
